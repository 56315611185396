[class^="ant-form"],
[class*=" ant-form"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}

[class^="ant-form"]::before,
[class*=" ant-form"]::before,
[class^="ant-form"]::after,
[class*=" ant-form"]::after {
  box-sizing: border-box;
}

[class^="ant-form"] [class^="ant-form"],
[class*=" ant-form"] [class^="ant-form"],
[class^="ant-form"] [class*=" ant-form"],
[class*=" ant-form"] [class*=" ant-form"] {
  box-sizing: border-box;
}

[class^="ant-form"] [class^="ant-form"]::before,
[class*=" ant-form"] [class^="ant-form"]::before,
[class^="ant-form"] [class*=" ant-form"]::before,
[class*=" ant-form"] [class*=" ant-form"]::before,
[class^="ant-form"] [class^="ant-form"]::after,
[class*=" ant-form"] [class^="ant-form"]::after,
[class^="ant-form"] [class*=" ant-form"]::after,
[class*=" ant-form"] [class*=" ant-form"]::after {
  box-sizing: border-box;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}

.ant-form input[type="search"] {
  box-sizing: border-box;
}

.ant-form input[type="radio"],
.ant-form input[type="checkbox"] {
  line-height: normal;
}

.ant-form input[type="file"] {
  display: block;
}

.ant-form input[type="range"] {
  display: block;
  width: 100%;
}

.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}

.ant-form input[type="file"]:focus,
.ant-form input[type="radio"]:focus,
.ant-form input[type="checkbox"]:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(142, 255, 30, 0.08);
}

.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
}

.ant-form .ant-form-text {
  display: inline-block;
  padding-inline-end: 12px;
}

.ant-form-small .ant-form-item .ant-form-item-label > label {
  height: 24px;
}

.ant-form-small .ant-form-item .ant-form-item-control-input {
  min-height: 24px;
}

.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: 40px;
}

.ant-form-large .ant-form-item .ant-form-item-control-input {
  min-height: 40px;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  margin-bottom: 24px;
  vertical-align: top;
}

.ant-form-item-with-help {
  transition: none;
}

.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}

.ant-form-item-has-warning .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form-item-has-error .ant-form-item-split {
  color: #faad14;
}

.ant-form-item .ant-form-item-label {
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: end;
  vertical-align: middle;
}

.ant-form-item .ant-form-item-label-left {
  text-align: start;
}

.ant-form-item .ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.5714285714285714;
  white-space: unset;
}

.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
}

.ant-form-item .ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-hide-required-mark
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item .ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-inline-start: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-form-hide-required-mark .ant-form-item .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}

.ant-form-item .ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
}

.ant-form-item .ant-form-item-label > label::after {
  content: ":";
  position: relative;
  margin-block: 0;
  margin-inline-start: 2px;
  margin-inline-end: 8px;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-no-colon::after {
  content: "\a0";
}

.ant-form-item .ant-form-item-control {
  --ant-display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ant-form-item
  .ant-form-item-control:first-child:not([class^="'ant-col-'"]):not([class*="' ant-col-'"]) {
  width: 100%;
}

.ant-form-item .ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.ant-form-item .ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item .ant-form-item-explain,
.ant-form-item .ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5714285714285714;
}

.ant-form-item .ant-form-item-explain-connected {
  width: 100%;
}

.ant-form-item .ant-form-item-extra {
  min-height: 24px;
  transition: color 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item .ant-form-item-explain-warning {
  color: #faad14;
}

.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}

.ant-form-item .ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation-name: css-dev-only-do-not-override-5gkde5-antZoomIn;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}

.ant-form-item .ant-form-item-feedback-icon-success {
  color: #52c41a;
}

.ant-form-item .ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}

.ant-form-item .ant-form-item-feedback-icon-warning {
  color: #faad14;
}

.ant-form-item .ant-form-item-feedback-icon-validating {
  color: #52c41a;
}

.ant-form-show-help {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-form-show-help-appear,
.ant-form-show-help-enter {
  opacity: 0;
}

.ant-form-show-help-appear-active,
.ant-form-show-help-enter-active {
  opacity: 1;
}

.ant-form-show-help-leave {
  opacity: 1;
}

.ant-form-show-help-leave-active {
  opacity: 0;
}

.ant-form-show-help .ant-form-show-help-item {
  overflow: hidden;
  transition:
    height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-form-show-help .ant-form-show-help-item.ant-form-show-help-item-appear,
.ant-form-show-help .ant-form-show-help-item.ant-form-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}

.ant-form-show-help .ant-form-show-help-item.ant-form-show-help-item-appear-active,
.ant-form-show-help .ant-form-show-help-item.ant-form-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}

.ant-form-show-help .ant-form-show-help-item.ant-form-show-help-item-leave-active {
  transform: translateY(-5px);
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}

.ant-form-horizontal .ant-form-item-label[class$="-24"] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*="-24 "] + .ant-form-item-control {
  min-width: unset;
}

.ant-form-item-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-item-horizontal .ant-form-item-control {
  flex: 1 1 0;
  min-width: 0;
}

.ant-form-item-horizontal .ant-form-item-label[class$="-24"] + .ant-form-item-control,
.ant-form-item-horizontal .ant-form-item-label[class*="-24 "] + .ant-form-item-control {
  min-width: unset;
}

.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}

.ant-form-inline .ant-form-item {
  flex: none;
  margin-inline-end: 16px;
  margin-bottom: 0;
}

.ant-form-inline .ant-form-item-row {
  flex-wrap: nowrap;
}

.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}

.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}

.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}

.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-row {
  flex-direction: column;
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label > label {
  height: auto;
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-control {
  width: 100%;
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-24.ant-form-item-label,
.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  white-space: initial;
  text-align: start;
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label > label,
.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-col-24.ant-form-item-label
  > label,
.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-col-xl-24.ant-form-item-label
  > label {
  margin: 0;
}

.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-form-item-label
  > label::after,
.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-col-24.ant-form-item-label
  > label::after,
.ant-form-vertical
  .ant-form-item:not(.ant-form-item-horizontal)
  .ant-col-xl-24.ant-form-item-label
  > label::after {
  visibility: hidden;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label > label::after {
    visibility: hidden;
  }

  .ant-form:not(.ant-form-inline) .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form:not(.ant-form-inline) .ant-form-item .ant-form-item-label:not([class*=" ant-col-xs"]),
  .ant-form:not(.ant-form-inline)
    .ant-form-item
    .ant-form-item-control:not([class*=" ant-col-xs"]) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-form .ant-form-item:not(.ant-form-item-horizontal) .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-xs-24.ant-form-item-label
    > label {
    margin: 0;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-xs-24.ant-form-item-label
    > label::after {
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .ant-form .ant-form-item:not(.ant-form-item-horizontal) .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-sm-24.ant-form-item-label
    > label {
    margin: 0;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-sm-24.ant-form-item-label
    > label::after {
    visibility: hidden;
  }
}

@media (max-width: 991px) {
  .ant-form .ant-form-item:not(.ant-form-item-horizontal) .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-md-24.ant-form-item-label
    > label {
    margin: 0;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-md-24.ant-form-item-label
    > label::after {
    visibility: hidden;
  }
}

@media (max-width: 1199px) {
  .ant-form .ant-form-item:not(.ant-form-item-horizontal) .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-lg-24.ant-form-item-label
    > label {
    margin: 0;
  }

  .ant-form
    .ant-form-item:not(.ant-form-item-horizontal)
    .ant-col-lg-24.ant-form-item-label
    > label::after {
    visibility: hidden;
  }
}

.ant-form-item-vertical .ant-form-item-row {
  flex-direction: column;
}

.ant-form-item-vertical .ant-form-item-label > label {
  height: auto;
}

.ant-form-item-vertical .ant-form-item-control {
  width: 100%;
}

.ant-form-item-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  white-space: initial;
  text-align: start;
}

.ant-form-item-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}

.ant-form-item-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  visibility: hidden;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label > label::after {
    visibility: hidden;
  }

  .ant-form:not(.ant-form-inline) .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form:not(.ant-form-inline) .ant-form-item .ant-form-item-label:not([class*=" ant-col-xs"]),
  .ant-form:not(.ant-form-inline)
    .ant-form-item
    .ant-form-item-control:not([class*=" ant-col-xs"]) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-form-item .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-col-xs-24.ant-form-item-label > label::after {
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .ant-form-item .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-col-sm-24.ant-form-item-label > label::after {
    visibility: hidden;
  }
}

@media (max-width: 991px) {
  .ant-form-item .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-col-md-24.ant-form-item-label > label::after {
    visibility: hidden;
  }
}

@media (max-width: 1199px) {
  .ant-form-item .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
  }

  .ant-form-item .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }

  .ant-form-item .ant-col-lg-24.ant-form-item-label > label::after {
    visibility: hidden;
  }
}

.ant-form .ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-form .ant-motion-collapse-legacy-active {
  transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-form .ant-motion-collapse {
  overflow: hidden;
  transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-app::before,
.ant-app::after {
  box-sizing: border-box;
}
.ant-app [class^="ant-app"],
.ant-app [class*=" ant-app"] {
  box-sizing: border-box;
}
.ant-app [class^="ant-app"]::before,
.ant-app [class*=" ant-app"]::before,
.ant-app [class^="ant-app"]::after,
.ant-app [class*=" ant-app"]::after {
  box-sizing: border-box;
}
.ant-app {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
.ant-app.ant-app-rtl {
  direction: rtl;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 16px;
  transition: all 0.2s;
}

.ant-input::-moz-placeholder {
  opacity: 1;
}

.ant-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition:
    all 0.3s,
    height 0s;
  resize: vertical;
}

.ant-input-lg {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 16px;
}

.ant-input-sm {
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 8px;
}

.ant-input-rtl,
.ant-input-textarea-rtl {
  direction: rtl;
}

.ant-input-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

.ant-input-outlined:hover {
  border-color: #73d13d;
  background-color: #ffffff;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border-color: #52c41a;
  box-shadow: 0 0 0 2px rgba(142, 255, 30, 0.08);
  outline: 0;
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-outlined.ant-input-disabled input[disabled],
.ant-input-outlined[disabled] input[disabled],
.ant-input-outlined.ant-input-disabled textarea[disabled],
.ant-input-outlined[disabled] textarea[disabled] {
  cursor: not-allowed;
}

.ant-input-outlined.ant-input-disabled:hover:not([disabled]),
.ant-input-outlined[disabled]:hover:not([disabled]) {
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
  border-color: #ffa39e;
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus,
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus-within {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
  outline: 0;
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) .ant-input-prefix,
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) .ant-input-suffix {
  color: #ff4d4f;
}

.ant-input-outlined.ant-input-status-error.ant-input-disabled {
  border-color: #ff4d4f;
}

.ant-input-outlined.ant-input-status-warning:not(.ant-input-disabled) {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #faad14;
}

.ant-input-outlined.ant-input-status-warning:not(.ant-input-disabled):hover {
  border-color: #ffd666;
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-status-warning:not(.ant-input-disabled):focus,
.ant-input-outlined.ant-input-status-warning:not(.ant-input-disabled):focus-within {
  border-color: #faad14;
  box-shadow: 0 0 0 2px rgba(255, 215, 5, 0.1);
  outline: 0;
  background-color: #ffffff;
}

.ant-input-outlined.ant-input-status-warning:not(.ant-input-disabled) .ant-input-prefix,
.ant-input-outlined.ant-input-status-warning:not(.ant-input-disabled) .ant-input-suffix {
  color: #faad14;
}

.ant-input-outlined.ant-input-status-warning.ant-input-disabled {
  border-color: #faad14;
}

.ant-input-filled {
  background: rgba(0, 0, 0, 0.04);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

input.ant-input-filled,
.ant-input-filled input,
textarea.ant-input-filled,
.ant-input-filled textarea {
  color: undefined;
}

.ant-input-filled:hover {
  background: rgba(0, 0, 0, 0.06);
}

.ant-input-filled:focus,
.ant-input-filled:focus-within {
  outline: 0;
  border-color: #52c41a;
  background-color: #ffffff;
}

.ant-input-filled.ant-input-disabled,
.ant-input-filled[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-filled.ant-input-disabled input[disabled],
.ant-input-filled[disabled] input[disabled],
.ant-input-filled.ant-input-disabled textarea[disabled],
.ant-input-filled[disabled] textarea[disabled] {
  cursor: not-allowed;
}

.ant-input-filled.ant-input-disabled:hover:not([disabled]),
.ant-input-filled[disabled]:hover:not([disabled]) {
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) {
  background: #fff2f0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

input.ant-input-filled.ant-input-status-error:not(.ant-input-disabled),
.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) input,
textarea.ant-input-filled.ant-input-status-error:not(.ant-input-disabled),
.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) textarea {
  color: #ff4d4f;
}

.ant-input-filled.ant-input-status-error:not(.ant-input-disabled):hover {
  background: #fff1f0;
}

.ant-input-filled.ant-input-status-error:not(.ant-input-disabled):focus,
.ant-input-filled.ant-input-status-error:not(.ant-input-disabled):focus-within {
  outline: 0;
  border-color: #ff4d4f;
  background-color: #ffffff;
}

.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) .ant-input-prefix,
.ant-input-filled.ant-input-status-error:not(.ant-input-disabled) .ant-input-suffix {
  color: #ff4d4f;
}

.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled) {
  background: #fffbe6;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

input.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled),
.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled) input,
textarea.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled),
.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled) textarea {
  color: #faad14;
}

.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled):hover {
  background: #fff1b8;
}

.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled):focus,
.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled):focus-within {
  outline: 0;
  border-color: #faad14;
  background-color: #ffffff;
}

.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled) .ant-input-prefix,
.ant-input-filled.ant-input-status-warning:not(.ant-input-disabled) .ant-input-suffix {
  color: #faad14;
}

.ant-input-borderless {
  background: transparent;
  border: none;
}

.ant-input-borderless:focus,
.ant-input-borderless:focus-within {
  outline: none;
}

.ant-input-borderless.ant-input-disabled,
.ant-input-borderless[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-borderless.ant-input-status-error,
.ant-input-borderless.ant-input-status-error input,
.ant-input-borderless.ant-input-status-error textarea {
  color: #ff4d4f;
}

.ant-input-borderless.ant-input-status-warning,
.ant-input-borderless.ant-input-status-warning input,
.ant-input-borderless.ant-input-status-warning textarea {
  color: #faad14;
}

.ant-input[type="color"] {
  height: 32px;
}

.ant-input[type="color"].ant-input-lg {
  height: 40px;
}

.ant-input[type="color"].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ant-input[type="search"]::-webkit-search-cancel-button,
.ant-input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.ant-input-textarea {
  position: relative;
}

.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}

.ant-input-textarea-show-count .ant-input-data-count {
  position: absolute;
  bottom: -22px;
  inset-inline-end: 0;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  pointer-events: none;
}

.ant-input-textarea-allow-clear > .ant-input,
.ant-input-textarea-affix-wrapper.ant-input-textarea-has-feedback .ant-input {
  padding-inline-end: 24px;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
  padding: 0;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper > textarea.ant-input {
  font-size: inherit;
  border: none;
  outline: none;
  background: transparent;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper > textarea.ant-input:focus {
  box-shadow: none !important;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper .ant-input-suffix {
  margin: 0;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper .ant-input-suffix > *:not(:last-child) {
  margin-inline: 0;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper .ant-input-suffix .ant-input-clear-icon {
  position: absolute;
  inset-inline-end: 11px;
  inset-block-start: 8px;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
  .ant-input-suffix
  .ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  inset-inline-end: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
  pointer-events: none;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper-sm
  .ant-input-suffix
  .ant-input-clear-icon {
  inset-inline-end: 7px;
}

.ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  border-radius: 16px;
  transition: all 0.2s;
}

.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}

.ant-input-affix-wrapper::placeholder {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}

.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition:
    all 0.3s,
    height 0s;
  resize: vertical;
}

.ant-input-affix-wrapper-lg {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 16px;
}

.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 8px;
}

.ant-input-affix-wrapper-rtl,
.ant-input-affix-wrapper-textarea-rtl {
  direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-disabled):hover {
  z-index: 1;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-disabled):hover {
  z-index: 0;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
}

.ant-input-affix-wrapper > input.ant-input,
.ant-input-affix-wrapper > textarea.ant-input {
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background: transparent;
  color: inherit;
}

.ant-input-affix-wrapper > input.ant-input::-ms-reveal,
.ant-input-affix-wrapper > textarea.ant-input::-ms-reveal {
  display: none;
}

.ant-input-affix-wrapper > input.ant-input:focus,
.ant-input-affix-wrapper > textarea.ant-input:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}

.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}

.ant-input-affix-wrapper .ant-input-prefix > *:not(:last-child),
.ant-input-affix-wrapper .ant-input-suffix > *:not(:last-child) {
  margin-inline-end: 8px;
}

.ant-input-affix-wrapper .ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-affix-wrapper .ant-input-show-count-has-suffix {
  margin-inline-end: 4px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 4px;
}

.ant-input-affix-wrapper .ant-input-suffix {
  margin-inline-start: 4px;
}

.ant-input-affix-wrapper .ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-input-affix-wrapper .ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-affix-wrapper .ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.88);
}

.ant-input-affix-wrapper .ant-input-clear-icon-hidden {
  visibility: hidden;
}

.ant-input-affix-wrapper .ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.88);
}

.ant-input-affix-wrapper-disabled .anticon.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: not-allowed;
}

.ant-input-affix-wrapper-disabled .anticon.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-input-group[class*="col-"] {
  padding-inline-end: 8px;
}

.ant-input-group[class*="col-"]:last-child {
  padding-inline-end: 0;
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 16px;
}

.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 8px;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-group > .ant-input {
  display: table-cell;
}

.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group .ant-input-group-addon,
.ant-input-group .ant-input-group-wrap {
  display: table-cell;
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}

.ant-input-group .ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group .ant-input-group-wrap:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-group .ant-input-group-wrap > * {
  display: block !important;
}

.ant-input-group .ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  border-radius: 16px;
  transition: all 0.3s;
  line-height: 1;
}

.ant-input-group .ant-input-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-group
  .ant-input-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}

.ant-input-group .ant-input-group-addon .ant-cascader-picker {
  margin: -9px -11px;
  background-color: transparent;
}

.ant-input-group .ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: start;
  border: 0;
  box-shadow: none;
}

.ant-input-group .ant-input {
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}

.ant-input-group .ant-input:focus {
  z-index: 1;
  border-inline-end-width: 1px;
}

.ant-input-group .ant-input:hover {
  z-index: 1;
  border-inline-end-width: 1px;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group .ant-input-group-addon:first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group .ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group .ant-input-group-addon:last-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group .ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-start-start-radius: 16px;
  border-end-start-radius: 16px;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-group.ant-input-group-compact {
  display: block;
}

.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: "";
}

.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: "";
}

.ant-input-group.ant-input-group-compact .ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact .ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-inline-end-width: 1px;
}

.ant-input-group.ant-input-group-compact
  .ant-input-group-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact
  .ant-input-group-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact
  .ant-input-group-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact
  .ant-input-group-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > * {
  display: inline-flex;
  float: none;
  vertical-align: top;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-input-number-affix-wrapper,
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-inline-end: -1px;
  border-inline-end-width: 1px;
}

.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-inline-end-width: 1px;
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-start-start-radius: 16px;
  border-end-start-radius: 16px;
}

.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-inline-end-width: 1px;
  border-start-end-radius: 16px;
  border-end-end-radius: 16px;
}

.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-inline-start: -1px;
}

.ant-input-group.ant-input-group-compact
  .ant-input-group-wrapper
  + .ant-input-group-wrapper
  .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact
  .ant-input-group-wrapper:not(:last-child).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon
  > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-group.ant-input-group-compact
  .ant-input-group-wrapper:not(:last-child).ant-input-search
  > .ant-input-group
  > .ant-input {
  border-start-start-radius: 16px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 16px;
}

.ant-input-group-rtl {
  direction: rtl;
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

.ant-input-group-wrapper-rtl {
  direction: rtl;
}

.ant-input-group-wrapper-lg .ant-input-group-addon {
  border-radius: 16px;
  font-size: 16px;
}

.ant-input-group-wrapper-sm .ant-input-group-addon {
  border-radius: 8px;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon:first-child {
  border-inline-end: 0;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon:last-child {
  border-inline-start: 0;
}

.ant-input-group-wrapper-outlined.ant-input-group-wrapper-status-error .ant-input-group-addon {
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.ant-input-group-wrapper-outlined.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  border-color: #faad14;
  color: #faad14;
}

.ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled .ant-input-group-addon {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled
  .ant-input-group-addon
  input[disabled],
.ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled
  .ant-input-group-addon
  textarea[disabled] {
  cursor: not-allowed;
}

.ant-input-group-wrapper-outlined.ant-input-group-wrapper-disabled
  .ant-input-group-addon:hover:not([disabled]) {
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-group-wrapper-filled .ant-input-group-addon {
  background: rgba(0, 0, 0, 0.04);
}

.ant-input-group-wrapper-filled
  .ant-input-group
  .ant-input-filled:not(:focus):not(:focus-within):not(:first-child) {
  border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-input-group-wrapper-filled
  .ant-input-group
  .ant-input-filled:not(:focus):not(:focus-within):not(:last-child) {
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-input-group-wrapper-filled.ant-input-group-wrapper-status-error .ant-input-group-addon {
  background: #fff2f0;
  color: #ff4d4f;
}

.ant-input-group-wrapper-filled.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  background: #fffbe6;
  color: #faad14;
}

.ant-input-group-wrapper-filled.ant-input-group-wrapper-disabled .ant-input-group-addon {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-group-wrapper-filled.ant-input-group-wrapper-disabled
  .ant-input-group-addon:first-child {
  border-inline-start: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.ant-input-group-wrapper-filled.ant-input-group-wrapper-disabled .ant-input-group-addon:last-child {
  border-inline-end: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.ant-input-group-wrapper:not(.ant-input-compact-first-item):not(
    .ant-input-compact-last-item
  ).ant-input-compact-item
  .ant-input,
.ant-input-group-wrapper:not(.ant-input-compact-first-item):not(
    .ant-input-compact-last-item
  ).ant-input-compact-item
  .ant-input-group-addon {
  border-radius: 0;
}

.ant-input-group-wrapper:not(.ant-input-compact-last-item).ant-input-compact-first-item .ant-input,
.ant-input-group-wrapper:not(.ant-input-compact-last-item).ant-input-compact-first-item
  .ant-input-group-addon {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-group-wrapper:not(.ant-input-compact-first-item).ant-input-compact-last-item .ant-input,
.ant-input-group-wrapper:not(.ant-input-compact-first-item).ant-input-compact-last-item
  .ant-input-group-addon {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-group-wrapper:not(.ant-input-compact-last-item).ant-input-compact-item
  .ant-input-affix-wrapper {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #73d13d;
}

.ant-input-search
  .ant-input:hover
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search
  .ant-input:focus
  + .ant-input-group-addon
  .ant-input-search-button:not(.ant-btn-primary) {
  border-inline-start-color: #73d13d;
}

.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search .ant-input-lg {
  line-height: 1.4998;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  inset-inline-start: -1px;
  padding: 0;
  border: 0;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  margin-inline-end: -1px;
  padding-top: 0;
  padding-bottom: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  box-shadow: none;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary):hover {
  color: #73d13d;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary):active {
  color: #389e0d;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
}

.ant-input-search .ant-input-search-button {
  height: 32px;
}

.ant-input-search .ant-input-search-button:hover,
.ant-input-search .ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-search-rtl {
  direction: rtl;
}

.ant-input-search.ant-input-compact-item:not(.ant-input-compact-last-item)
  .ant-input-group-addon
  .ant-input-search-button {
  margin-inline-end: -1px;
  border-radius: 0;
}

.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item) .ant-input,
.ant-input-search.ant-input-compact-item:not(.ant-input-compact-first-item)
  .ant-input-affix-wrapper {
  border-radius: 0;
}

.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:hover,
.ant-input-search.ant-input-compact-item > .ant-input:hover,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:hover,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:focus,
.ant-input-search.ant-input-compact-item > .ant-input:focus,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:focus,
.ant-input-search.ant-input-compact-item > .ant-input-group-addon .ant-input-search-button:active,
.ant-input-search.ant-input-compact-item > .ant-input:active,
.ant-input-search.ant-input-compact-item .ant-input-affix-wrapper:active {
  z-index: 2;
}

.ant-input-search.ant-input-compact-item > .ant-input-affix-wrapper-focused {
  z-index: 2;
}

.ant-input-out-of-range,
.ant-input-out-of-range input,
.ant-input-out-of-range textarea,
.ant-input-out-of-range .ant-input-show-count-suffix,
.ant-input-out-of-range .ant-input-data-count {
  color: #ff4d4f;
}

.ant-input-compact-item:not(.ant-input-compact-last-item) {
  margin-inline-end: -1px;
}

.ant-input-compact-item:hover,
.ant-input-compact-item:focus,
.ant-input-compact-item:active {
  z-index: 2;
}

.ant-input-compact-item[disabled] {
  z-index: 0;
}

.ant-input-compact-item:not(.ant-input-compact-first-item):not(.ant-input-compact-last-item) {
  border-radius: 0;
}

.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-first-item,
.ant-input-compact-item:not(.ant-input-compact-last-item).ant-input-compact-first-item.ant-input-sm,
.ant-input-compact-item:not(
    .ant-input-compact-last-item
  ).ant-input-compact-first-item.ant-input-lg {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-compact-item:not(.ant-input-compact-first-item).ant-input-compact-last-item,
.ant-input-compact-item:not(.ant-input-compact-first-item).ant-input-compact-last-item.ant-input-sm,
.ant-input-compact-item:not(
    .ant-input-compact-first-item
  ).ant-input-compact-last-item.ant-input-lg {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

[class^="ant-input-number"],
[class*=" ant-input-number"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}

[class^="ant-input-number"]::before,
[class*=" ant-input-number"]::before,
[class^="ant-input-number"]::after,
[class*=" ant-input-number"]::after {
  box-sizing: border-box;
}

[class^="ant-input-number"] [class^="ant-input-number"],
[class*=" ant-input-number"] [class^="ant-input-number"],
[class^="ant-input-number"] [class*=" ant-input-number"],
[class*=" ant-input-number"] [class*=" ant-input-number"] {
  box-sizing: border-box;
}

[class^="ant-input-number"] [class^="ant-input-number"]::before,
[class*=" ant-input-number"] [class^="ant-input-number"]::before,
[class^="ant-input-number"] [class*=" ant-input-number"]::before,
[class*=" ant-input-number"] [class*=" ant-input-number"]::before,
[class^="ant-input-number"] [class^="ant-input-number"]::after,
[class*=" ant-input-number"] [class^="ant-input-number"]::after,
[class^="ant-input-number"] [class*=" ant-input-number"]::after,
[class*=" ant-input-number"] [class*=" ant-input-number"]::after {
  box-sizing: border-box;
}

.ant-input-number {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 90px;
  min-width: 0;
  border-radius: 16px;
  transition: all 0.2s;
}

.ant-input-number::-moz-placeholder {
  opacity: 1;
}

.ant-input-number::placeholder {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}

.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}

textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition:
    all 0.3s,
    height 0s;
  resize: vertical;
}

.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 16px;
}

.ant-input-number-lg input.ant-input-number-input {
  height: 38px;
  padding: 7px 11px;
}

.ant-input-number-sm {
  padding: 0;
  font-size: 14px;
  border-radius: 8px;
}

.ant-input-number-sm input.ant-input-number-input {
  height: 22px;
  padding: 0px 7px;
}

.ant-input-number-rtl,
.ant-input-number-textarea-rtl {
  direction: rtl;
}

.ant-input-number-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

.ant-input-number-outlined:hover {
  border-color: #73d13d;
  background-color: #ffffff;
}

.ant-input-number-outlined:focus,
.ant-input-number-outlined:focus-within {
  border-color: #52c41a;
  box-shadow: 0 0 0 2px rgba(142, 255, 30, 0.08);
  outline: 0;
  background-color: #ffffff;
}

.ant-input-number-outlined.ant-input-number-disabled,
.ant-input-number-outlined[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-outlined.ant-input-number-disabled input[disabled],
.ant-input-number-outlined[disabled] input[disabled],
.ant-input-number-outlined.ant-input-number-disabled textarea[disabled],
.ant-input-number-outlined[disabled] textarea[disabled] {
  cursor: not-allowed;
}

.ant-input-number-outlined.ant-input-number-disabled:hover:not([disabled]),
.ant-input-number-outlined[disabled]:hover:not([disabled]) {
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled) {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #ff4d4f;
}

.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled):hover {
  border-color: #ffa39e;
  background-color: #ffffff;
}

.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled):focus,
.ant-input-number-outlined.ant-input-number-status-error:not(
    .ant-input-number-disabled
  ):focus-within {
  border-color: #ff4d4f;
  box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
  outline: 0;
  background-color: #ffffff;
}

.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled)
  .ant-input-number-prefix,
.ant-input-number-outlined.ant-input-number-status-error:not(.ant-input-number-disabled)
  .ant-input-number-suffix {
  color: #ff4d4f;
}

.ant-input-number-outlined.ant-input-number-status-error.ant-input-number-disabled {
  border-color: #ff4d4f;
}

.ant-input-number-outlined.ant-input-number-status-warning:not(.ant-input-number-disabled) {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #faad14;
}

.ant-input-number-outlined.ant-input-number-status-warning:not(.ant-input-number-disabled):hover {
  border-color: #ffd666;
  background-color: #ffffff;
}

.ant-input-number-outlined.ant-input-number-status-warning:not(.ant-input-number-disabled):focus,
.ant-input-number-outlined.ant-input-number-status-warning:not(
    .ant-input-number-disabled
  ):focus-within {
  border-color: #faad14;
  box-shadow: 0 0 0 2px rgba(255, 215, 5, 0.1);
  outline: 0;
  background-color: #ffffff;
}

.ant-input-number-outlined.ant-input-number-status-warning:not(.ant-input-number-disabled)
  .ant-input-number-prefix,
.ant-input-number-outlined.ant-input-number-status-warning:not(.ant-input-number-disabled)
  .ant-input-number-suffix {
  color: #faad14;
}

.ant-input-number-outlined.ant-input-number-status-warning.ant-input-number-disabled {
  border-color: #faad14;
}

.ant-input-number-outlined .ant-input-number-handler-wrap {
  background: #ffffff;
}

.ant-input-number-outlined .ant-input-number-handler-wrap .ant-input-number-handler-down {
  border-block-start: 1px solid #d9d9d9;
}

.ant-input-number-filled {
  background: rgba(0, 0, 0, 0.04);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

input.ant-input-number-filled,
.ant-input-number-filled input,
textarea.ant-input-number-filled,
.ant-input-number-filled textarea {
  color: undefined;
}

.ant-input-number-filled:hover {
  background: rgba(0, 0, 0, 0.06);
}

.ant-input-number-filled:focus,
.ant-input-number-filled:focus-within {
  outline: 0;
  border-color: #52c41a;
  background-color: #ffffff;
}

.ant-input-number-filled.ant-input-number-disabled,
.ant-input-number-filled[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-filled.ant-input-number-disabled input[disabled],
.ant-input-number-filled[disabled] input[disabled],
.ant-input-number-filled.ant-input-number-disabled textarea[disabled],
.ant-input-number-filled[disabled] textarea[disabled] {
  cursor: not-allowed;
}

.ant-input-number-filled.ant-input-number-disabled:hover:not([disabled]),
.ant-input-number-filled[disabled]:hover:not([disabled]) {
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled) {
  background: #fff2f0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

input.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled),
.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled) input,
textarea.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled),
.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled) textarea {
  color: #ff4d4f;
}

.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled):hover {
  background: #fff1f0;
}

.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled):focus,
.ant-input-number-filled.ant-input-number-status-error:not(
    .ant-input-number-disabled
  ):focus-within {
  outline: 0;
  border-color: #ff4d4f;
  background-color: #ffffff;
}

.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled)
  .ant-input-number-prefix,
.ant-input-number-filled.ant-input-number-status-error:not(.ant-input-number-disabled)
  .ant-input-number-suffix {
  color: #ff4d4f;
}

.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled) {
  background: #fffbe6;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

input.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled),
.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled) input,
textarea.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled),
.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled) textarea {
  color: #faad14;
}

.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled):hover {
  background: #fff1b8;
}

.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled):focus,
.ant-input-number-filled.ant-input-number-status-warning:not(
    .ant-input-number-disabled
  ):focus-within {
  outline: 0;
  border-color: #faad14;
  background-color: #ffffff;
}

.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled)
  .ant-input-number-prefix,
.ant-input-number-filled.ant-input-number-status-warning:not(.ant-input-number-disabled)
  .ant-input-number-suffix {
  color: #faad14;
}

.ant-input-number-filled .ant-input-number-handler-wrap {
  background: #f0f0f0;
}

.ant-input-number-filled .ant-input-number-handler-wrap .ant-input-number-handler-down {
  border-block-start: 1px solid #d9d9d9;
}

.ant-input-number-filled:focus-within .ant-input-number-handler-wrap {
  background: #ffffff;
}

.ant-input-number-borderless {
  background: transparent;
  border: none;
}

.ant-input-number-borderless:focus,
.ant-input-number-borderless:focus-within {
  outline: none;
}

.ant-input-number-borderless.ant-input-number-disabled,
.ant-input-number-borderless[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-borderless.ant-input-number-status-error,
.ant-input-number-borderless.ant-input-number-status-error input,
.ant-input-number-borderless.ant-input-number-status-error textarea {
  color: #ff4d4f;
}

.ant-input-number-borderless.ant-input-number-status-warning,
.ant-input-number-borderless.ant-input-number-status-warning input,
.ant-input-number-borderless.ant-input-number-status-warning textarea {
  color: #faad14;
}

.ant-input-number-rtl {
  direction: rtl;
}

.ant-input-number-rtl .ant-input-number-input {
  direction: rtl;
}

.ant-input-number-out-of-range .ant-input-number-input-wrap input {
  color: #ff4d4f;
}

.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-input-number-group[class*="col-"] {
  padding-inline-end: 8px;
}

.ant-input-number-group[class*="col-"]:last-child {
  padding-inline-end: 0;
}

.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 7px 11px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 16px;
}

.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 8px;
}

.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}

.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}

.ant-input-number-group > .ant-input-number {
  display: table-cell;
}

.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-number-group .ant-input-number-group-addon,
.ant-input-number-group .ant-input-number-group-wrap {
  display: table-cell;
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}

.ant-input-number-group .ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group .ant-input-number-group-wrap:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ant-input-number-group .ant-input-number-group-wrap > * {
  display: block !important;
}

.ant-input-number-group .ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  border-radius: 16px;
  transition: all 0.3s;
  line-height: 1;
}

.ant-input-number-group .ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}

.ant-input-number-group
  .ant-input-number-group-addon
  .ant-select.ant-select-single:not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}

.ant-input-number-group .ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -11px;
  background-color: transparent;
}

.ant-input-number-group .ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: start;
  border: 0;
  box-shadow: none;
}

.ant-input-number-group .ant-input-number {
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}

.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-inline-end-width: 1px;
}

.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-inline-end-width: 1px;
}

.ant-input-number-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}

.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group .ant-input-number-group-addon:first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group .ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group .ant-input-number-group-addon:last-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group .ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-number-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-start-start-radius: 16px;
  border-end-start-radius: 16px;
}

.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-number-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}

.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: "";
}

.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: "";
}

.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact
  > .ant-input-number:not(:first-child):not(:last-child) {
  border-inline-end-width: 1px;
}

.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-input-number:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-flex;
  float: none;
  vertical-align: top;
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-number-affix-wrapper,
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}

.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-inline-end: -1px;
  border-inline-end-width: 1px;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-select-auto-complete
  .ant-input-number,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input-number,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-input-number-group-wrapper
  .ant-input-number {
  border-inline-end-width: 1px;
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-select-auto-complete
  .ant-input-number:hover,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-cascader-picker
  .ant-input-number:hover,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-input-number-group-wrapper
  .ant-input-number:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-select-auto-complete
  .ant-input-number:focus,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-cascader-picker
  .ant-input-number:focus,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-input-number-group-wrapper
  .ant-input-number:focus {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}

.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-select:first-child
  > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input-number,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-cascader-picker:first-child
  .ant-input-number {
  border-start-start-radius: 16px;
  border-end-start-radius: 16px;
}

.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-select:last-child
  > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-cascader-picker:last-child
  .ant-input-number,
.ant-input-number-group.ant-input-number-group-compact
  > .ant-cascader-picker-focused:last-child
  .ant-input-number {
  border-inline-end-width: 1px;
  border-start-end-radius: 16px;
  border-end-end-radius: 16px;
}

.ant-input-number-group.ant-input-number-group-compact
  > .ant-select-auto-complete
  .ant-input-number {
  vertical-align: top;
}

.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrapper
  + .ant-input-number-group-wrapper {
  margin-inline-start: -1px;
}

.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrapper
  + .ant-input-number-group-wrapper
  .ant-input-number-affix-wrapper {
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrapper:not(:last-child).ant-input-number-search
  > .ant-input-number-group
  > .ant-input-number-group-addon
  > .ant-input-number-search-button {
  border-radius: 0;
}

.ant-input-number-group.ant-input-number-group-compact
  .ant-input-number-group-wrapper:not(:last-child).ant-input-number-search
  > .ant-input-number-group
  > .ant-input-number {
  border-start-start-radius: 16px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 16px;
}

.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}

.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}

.ant-input-number-group-wrapper-lg .ant-input-number-group-addon {
  border-radius: 16px;
  font-size: 16px;
}

.ant-input-number-group-wrapper-sm .ant-input-number-group-addon {
  border-radius: 8px;
}

.ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
}

.ant-input-number-group-wrapper-outlined .ant-input-number-group-addon:first-child {
  border-inline-end: 0;
}

.ant-input-number-group-wrapper-outlined .ant-input-number-group-addon:last-child {
  border-inline-start: 0;
}

.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-status-error
  .ant-input-number-group-addon {
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-status-warning
  .ant-input-number-group-addon {
  border-color: #faad14;
  color: #faad14;
}

.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon
  input[disabled],
.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon
  textarea[disabled] {
  cursor: not-allowed;
}

.ant-input-number-group-wrapper-outlined.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon:hover:not([disabled]) {
  border-color: #d9d9d9;
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-input-number-group-wrapper-filled .ant-input-number-group-addon {
  background: rgba(0, 0, 0, 0.04);
}

.ant-input-number-group-wrapper-filled
  .ant-input-number-group
  .ant-input-number-filled:not(:focus):not(:focus-within):not(:first-child) {
  border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-input-number-group-wrapper-filled
  .ant-input-number-group
  .ant-input-number-filled:not(:focus):not(:focus-within):not(:last-child) {
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-input-number-group-wrapper-filled.ant-input-number-group-wrapper-status-error
  .ant-input-number-group-addon {
  background: #fff2f0;
  color: #ff4d4f;
}

.ant-input-number-group-wrapper-filled.ant-input-number-group-wrapper-status-warning
  .ant-input-number-group-addon {
  background: #fffbe6;
  color: #faad14;
}

.ant-input-number-group-wrapper-filled.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-group-wrapper-filled.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon:first-child {
  border-inline-start: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.ant-input-number-group-wrapper-filled.ant-input-number-group-wrapper-disabled
  .ant-input-number-group-addon:last-child {
  border-inline-end: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.ant-input-number-group-wrapper:not(.ant-input-number-compact-first-item):not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-item
  .ant-input-number,
.ant-input-number-group-wrapper:not(.ant-input-number-compact-first-item):not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-item
  .ant-input-number-group-addon {
  border-radius: 0;
}

.ant-input-number-group-wrapper:not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-first-item
  .ant-input-number,
.ant-input-number-group-wrapper:not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-first-item
  .ant-input-number-group-addon {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.ant-input-number-group-wrapper:not(
    .ant-input-number-compact-first-item
  ).ant-input-number-compact-last-item
  .ant-input-number,
.ant-input-number-group-wrapper:not(
    .ant-input-number-compact-first-item
  ).ant-input-number-compact-last-item
  .ant-input-number-group-addon {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}

.ant-input-number .ant-input-number-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: inherit;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  width: 100%;
  text-align: start;
  background-color: transparent;
  border: 0;
  border-radius: 16px;
  outline: 0;
  transition: all 0.2s linear;
  appearance: textfield;
}

.ant-input-number .ant-input-number-input::-moz-placeholder {
  opacity: 1;
}

.ant-input-number .ant-input-number-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}

.ant-input-number .ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}

.ant-input-number .ant-input-number-input[type="number"]::-webkit-inner-spin-button,
.ant-input-number .ant-input-number-input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
  webkit-appearance: none;
  appearance: none;
}

.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  width: 22px;
  opacity: 1;
}

.ant-input-number .ant-input-number-handler-wrap {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  width: 0;
  opacity: 0;
  height: 100%;
  border-start-start-radius: 0;
  border-start-end-radius: 16px;
  border-end-end-radius: 16px;
  border-end-start-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: all 0.2s;
  overflow: hidden;
}

.ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  height: 40%;
}

.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-up-inner,
.ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-down-inner {
  margin-inline-end: 0;
  font-size: 7px;
}

.ant-input-number .ant-input-number-handler {
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  cursor: pointer;
  border-inline-start: 1px solid #d9d9d9;
  transition: all 0.2s linear;
}

.ant-input-number .ant-input-number-handler:active {
  background: rgba(0, 0, 0, 0.02);
}

.ant-input-number .ant-input-number-handler:hover {
  height: 60%;
}

.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #52c41a;
}

.ant-input-number .ant-input-number-handler-up-inner,
.ant-input-number .ant-input-number-handler-down-inner {
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s linear;
  user-select: none;
}

.ant-input-number .ant-input-number-handler-up-inner > *,
.ant-input-number .ant-input-number-handler-down-inner > * {
  line-height: 1;
}

.ant-input-number .ant-input-number-handler-up-inner svg,
.ant-input-number .ant-input-number-handler-down-inner svg {
  display: inline-block;
}

.ant-input-number .ant-input-number-handler-up {
  border-start-end-radius: 16px;
}

.ant-input-number .ant-input-number-handler-down {
  border-end-end-radius: 16px;
}

.ant-input-number-lg .ant-input-number-handler-wrap {
  border-start-end-radius: 16px;
  border-end-end-radius: 16px;
}

.ant-input-number-lg .ant-input-number-handler-up {
  border-start-end-radius: 16px;
}

.ant-input-number-lg .ant-input-number-handler-down {
  border-end-end-radius: 16px;
}

.ant-input-number-sm .ant-input-number-handler-wrap {
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
}

.ant-input-number-sm .ant-input-number-handler-up {
  border-start-end-radius: 8px;
}

.ant-input-number-sm .ant-input-number-handler-down {
  border-end-end-radius: 8px;
}

.ant-input-number-disabled .ant-input-number-handler-wrap,
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number-disabled .ant-input-number-input,
.ant-input-number-readonly .ant-input-number-input {
  color: inherit;
}

.ant-input-number .ant-input-number-handler-up-disabled,
.ant-input-number .ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}

.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 90px;
  min-width: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  border-radius: 16px;
  transition: all 0.2s;
  align-items: center;
  padding-inline-start: 11px;
}

.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 4px 0;
}

.ant-input-number-affix-wrapper::-moz-placeholder {
  opacity: 1;
}

.ant-input-number-affix-wrapper::placeholder {
  color: rgba(0, 0, 0, 0.25);
  user-select: none;
}

.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}

textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition:
    all 0.3s,
    height 0s;
  resize: vertical;
}

.ant-input-number-affix-wrapper-lg {
  border-radius: 16px;
  padding-inline-start: 11px;
}

.ant-input-number-affix-wrapper-lg input.ant-input-number-input {
  padding: 7px 0;
}

.ant-input-number-affix-wrapper-sm {
  border-radius: 8px;
  padding-inline-start: 7px;
}

.ant-input-number-affix-wrapper-sm input.ant-input-number-input {
  padding: 0px 0;
}

.ant-input-number-affix-wrapper-rtl,
.ant-input-number-affix-wrapper-textarea-rtl {
  direction: rtl;
}

.ant-input-number-affix-wrapper:not(.ant-input-number-disabled):hover {
  z-index: 1;
}

.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}

.ant-input-number-affix-wrapper-disabled > .ant-input-number-disabled {
  background: transparent;
}

.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}

.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}

.ant-input-number-affix-wrapper::before {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: "\a0";
}

.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}

.ant-input-number-affix-wrapper .ant-input-number {
  position: static;
  color: inherit;
}

.ant-input-number-affix-wrapper .ant-input-number-prefix,
.ant-input-number-affix-wrapper .ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}

.ant-input-number-affix-wrapper .ant-input-number-prefix {
  margin-inline-end: 4px;
}

.ant-input-number-affix-wrapper .ant-input-number-suffix {
  inset-block-start: 0;
  inset-inline-end: 0;
  height: 100%;
  margin-inline-end: 11px;
  margin-inline-start: 4px;
  transition: margin 0.2s;
}

.ant-input-number-affix-wrapper:hover .ant-input-number-handler-wrap,
.ant-input-number-affix-wrapper-focused .ant-input-number-handler-wrap {
  width: 22px;
  opacity: 1;
}

.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-without-controls):hover
  .ant-input-number-suffix {
  margin-inline-end: 33px;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-last-item) {
  margin-inline-end: -1px;
}

.ant-input-number-compact-item:hover,
.ant-input-number-compact-item:focus,
.ant-input-number-compact-item:active {
  z-index: 2;
}

.ant-input-number-compact-item[disabled] {
  z-index: 0;
}

.ant-input-number-compact-item:not(.ant-input-number-compact-first-item):not(
    .ant-input-number-compact-last-item
  ) {
  border-radius: 0;
}

.ant-input-number-compact-item:not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-first-item,
.ant-input-number-compact-item:not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-first-item.ant-input-number-sm,
.ant-input-number-compact-item:not(
    .ant-input-number-compact-last-item
  ).ant-input-number-compact-first-item.ant-input-number-lg {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.ant-input-number-compact-item:not(
    .ant-input-number-compact-first-item
  ).ant-input-number-compact-last-item,
.ant-input-number-compact-item:not(
    .ant-input-number-compact-first-item
  ).ant-input-number-compact-last-item.ant-input-number-sm,
.ant-input-number-compact-item:not(
    .ant-input-number-compact-first-item
  ).ant-input-number-compact-last-item.ant-input-number-lg {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.ant-layout {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-layout::before,
.ant-layout::after {
  box-sizing: border-box;
}
.ant-layout [class^="ant-layout"],
.ant-layout [class*=" ant-layout"] {
  box-sizing: border-box;
}
.ant-layout [class^="ant-layout"]::before,
.ant-layout [class*=" ant-layout"]::before,
.ant-layout [class^="ant-layout"]::after,
.ant-layout [class*=" ant-layout"]::after {
  box-sizing: border-box;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f5f5f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout .ant-layout-header,
.ant-layout.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #001529;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  background: #f5f5f5;
}
.ant-layout-content {
  flex: auto;
  color: rgba(0, 0, 0, 0.88);
  min-height: 0;
}
.ant-list {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-list::before,
.ant-list::after {
  box-sizing: border-box;
}
.ant-list [class^="ant-list"],
.ant-list [class*=" ant-list"] {
  box-sizing: border-box;
}
.ant-list [class^="ant-list"]::before,
.ant-list [class*=" ant-list"]::before,
.ant-list [class^="ant-list"]::after,
.ant-list [class*=" ant-list"]::after {
  box-sizing: border-box;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: relative;
}
.ant-list * {
  outline: 0;
}
.ant-list .ant-list-header {
  background: transparent;
}
.ant-list .ant-list-footer {
  background: transparent;
}
.ant-list .ant-list-header,
.ant-list .ant-list-footer {
  padding-block: 12px;
}
.ant-list .ant-list-pagination {
  margin-block-start: 24px;
}
.ant-list .ant-list-pagination .ant-pagination-options {
  text-align: start;
}
.ant-list .ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list .ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list .ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.88);
}
.ant-list .ant-list-item .ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
  margin-inline-end: 16px;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.88);
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin: 0 0 4px 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.88);
  transition: all 0.3s;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title > a:hover {
  color: #52c41a;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5714285714285714;
}
.ant-list .ant-list-item .ant-list-item-action {
  flex: 0 0 auto;
  margin-inline-start: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list .ant-list-item .ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5714285714285714;
  text-align: center;
}
.ant-list .ant-list-item .ant-list-item-action > li:first-child {
  padding-inline-start: 0;
}
.ant-list .ant-list-item .ant-list-item-action .ant-list-item-action-split {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 0;
  width: 1px;
  height: 14px;
  transform: translateY(-50%);
  background-color: rgba(5, 5, 5, 0.06);
}
.ant-list .ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list .ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list .ant-list-item-no-flex {
  display: block;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-block-end: 16px;
  padding-block: 0;
  border-block-end: none;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item .ant-list-item-extra {
  margin-inline-start: 24px;
}
.ant-list-vertical .ant-list-item .ant-list-item-meta {
  margin-block-end: 16px;
}
.ant-list-vertical .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
  margin-block-start: 0;
  margin-block-end: 12px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 1.5;
}
.ant-list-vertical .ant-list-item .ant-list-item-action {
  margin-block-start: 16px;
  margin-inline-start: auto;
}
.ant-list-vertical .ant-list-item .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item .ant-list-item-action > li:first-child {
  padding-inline-start: 0;
}
.ant-list-split .ant-list-item {
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}
.ant-list-split .ant-list-item:last-child {
  border-block-end: none;
}
.ant-list-split .ant-list-header {
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 16px;
}
.ant-list-bordered .ant-list-header,
.ant-list-bordered .ant-list-footer,
.ant-list-bordered .ant-list-item {
  padding-inline: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item,
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list .ant-list-item .ant-list-item-action {
    margin-inline-start: 24px;
  }
  .ant-list-vertical .ant-list-item .ant-list-item-extra {
    margin-inline-start: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list .ant-list-item .ant-list-action {
    margin-inline-start: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-row {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  box-sizing: border-box;
}
.ant-row [class^="ant-row"],
.ant-row [class*=" ant-row"] {
  box-sizing: border-box;
}
.ant-row [class^="ant-row"]::before,
.ant-row [class*=" ant-row"]::before,
.ant-row [class^="ant-row"]::after,
.ant-row [class*=" ant-row"]::after {
  box-sizing: border-box;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-segmented {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-segmented::before,
.ant-segmented::after {
  box-sizing: border-box;
}
.ant-segmented [class^="ant-segmented"],
.ant-segmented [class*=" ant-segmented"] {
  box-sizing: border-box;
}
.ant-segmented [class^="ant-segmented"]::before,
.ant-segmented [class*=" ant-segmented"]::before,
.ant-segmented [class^="ant-segmented"]::after,
.ant-segmented [class*=" ant-segmented"]::after {
  box-sizing: border-box;
}
.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  display: inline-block;
  background: #f5f5f5;
  border-radius: 16px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented .ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  flex-direction: row;
  width: 100%;
}
.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}
.ant-segmented.ant-segmented-vertical .ant-segmented-group {
  flex-direction: column;
}
.ant-segmented.ant-segmented-vertical .ant-segmented-thumb {
  width: 100%;
  height: 0;
  padding: 0 4px;
}
.ant-segmented.ant-segmented-block {
  display: flex;
}
.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1;
  min-width: 0;
}
.ant-segmented .ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 8px;
  transform: translateZ(0);
}
.ant-segmented .ant-segmented-item-selected {
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.88);
}
.ant-segmented .ant-segmented-item::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  inset-inline-start: 0;
  border-radius: inherit;
  transition: background-color 0.2s;
  pointer-events: none;
}
.ant-segmented
  .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
  color: rgba(0, 0, 0, 0.88);
}
.ant-segmented
  .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(
    .ant-segmented-item-disabled
  )::after {
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-segmented
  .ant-segmented-item:active:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
  color: rgba(0, 0, 0, 0.88);
}
.ant-segmented
  .ant-segmented-item:active:not(.ant-segmented-item-selected):not(
    .ant-segmented-item-disabled
  )::after {
  background-color: rgba(0, 0, 0, 0.15);
}
.ant-segmented .ant-segmented-item-label {
  min-height: 28px;
  line-height: 28px;
  padding: 0 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-segmented .ant-segmented-item-icon + * {
  margin-inline-start: 6px;
}
.ant-segmented .ant-segmented-item-input {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-segmented .ant-segmented-thumb {
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
  border-radius: 8px;
  transition:
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented
  .ant-segmented-thumb
  ~ .ant-segmented-item:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled)::after {
  background-color: transparent;
}
.ant-segmented.ant-segmented-lg {
  border-radius: 16px;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  line-height: 36px;
  padding: 0 11px;
  font-size: 16px;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item,
.ant-segmented.ant-segmented-lg .ant-segmented-thumb {
  border-radius: 16px;
}
.ant-segmented.ant-segmented-sm {
  border-radius: 8px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  line-height: 20px;
  padding: 0 7px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item,
.ant-segmented.ant-segmented-sm .ant-segmented-thumb {
  border-radius: 2px;
}
.ant-segmented-disabled .ant-segmented-item,
.ant-segmented-disabled .ant-segmented-item:hover,
.ant-segmented-disabled .ant-segmented-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented .ant-segmented-item-disabled,
.ant-segmented .ant-segmented-item-disabled:hover,
.ant-segmented .ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented .ant-segmented-thumb-motion-appear-active {
  transition:
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.ant-skeleton {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-skeleton::before,
.ant-skeleton::after {
  box-sizing: border-box;
}
.ant-skeleton [class^="ant-skeleton"],
.ant-skeleton [class*=" ant-skeleton"] {
  box-sizing: border-box;
}
.ant-skeleton [class^="ant-skeleton"]::before,
.ant-skeleton [class*=" ant-skeleton"]::before,
.ant-skeleton [class^="ant-skeleton"]::after,
.ant-skeleton [class*=" ant-skeleton"]::after {
  box-sizing: border-box;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton .ant-skeleton-header {
  display: table-cell;
  padding-inline-end: 16px;
  vertical-align: top;
}
.ant-skeleton .ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(0, 0, 0, 0.06);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton .ant-skeleton-header .ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton .ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton .ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton .ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.ant-skeleton .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-block-start: 24px;
}
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-block-start: 16px;
}
.ant-skeleton
  .ant-skeleton-content
  .ant-skeleton-paragraph
  > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-block-start: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-block-start: 28px;
}
.ant-skeleton.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(0, 0, 0, 0.06);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-image .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-image .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton.ant-skeleton-element
  .ant-skeleton-image
  .ant-skeleton-image-svg.ant-skeleton-image-svg-circle {
  border-radius: 50%;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06) 25%,
    rgba(0, 0, 0, 0.15) 37%,
    rgba(0, 0, 0, 0.06) 63%
  );
  background-size: 400% 100%;
  animation-name: css-dev-only-do-not-override-5gkde5-ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
@keyframes css-dev-only-do-not-override-5gkde5-ant-skeleton-loading {
  0 {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-row-bottom {
  align-items: flex-end;
}
. .ant-col {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-col::before,
.ant-col::after {
  box-sizing: border-box;
}
.ant-col [class^="ant-col"],
.ant-col [class*=" ant-col"] {
  box-sizing: border-box;
}
.ant-col [class^="ant-col"]::before,
.ant-col [class*=" ant-col"]::before,
.ant-col [class^="ant-col"]::after,
.ant-col [class*=" ant-col"]::after {
  box-sizing: border-box;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  inset-inline-start: 100%;
}
.ant-col-pull-24 {
  inset-inline-end: 100%;
}
.ant-col-offset-24 {
  margin-inline-start: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 95.83333333333334%;
  max-width: 95.83333333333334%;
}
.ant-col-push-23 {
  inset-inline-start: 95.83333333333334%;
}
.ant-col-pull-23 {
  inset-inline-end: 95.83333333333334%;
}
.ant-col-offset-23 {
  margin-inline-start: 95.83333333333334%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 91.66666666666666%;
  max-width: 91.66666666666666%;
}
.ant-col-push-22 {
  inset-inline-start: 91.66666666666666%;
}
.ant-col-pull-22 {
  inset-inline-end: 91.66666666666666%;
}
.ant-col-offset-22 {
  margin-inline-start: 91.66666666666666%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  inset-inline-start: 87.5%;
}
.ant-col-pull-21 {
  inset-inline-end: 87.5%;
}
.ant-col-offset-21 {
  margin-inline-start: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 83.33333333333334%;
  max-width: 83.33333333333334%;
}
.ant-col-push-20 {
  inset-inline-start: 83.33333333333334%;
}
.ant-col-pull-20 {
  inset-inline-end: 83.33333333333334%;
}
.ant-col-offset-20 {
  margin-inline-start: 83.33333333333334%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 79.16666666666666%;
  max-width: 79.16666666666666%;
}
.ant-col-push-19 {
  inset-inline-start: 79.16666666666666%;
}
.ant-col-pull-19 {
  inset-inline-end: 79.16666666666666%;
}
.ant-col-offset-19 {
  margin-inline-start: 79.16666666666666%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  inset-inline-start: 75%;
}
.ant-col-pull-18 {
  inset-inline-end: 75%;
}
.ant-col-offset-18 {
  margin-inline-start: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 70.83333333333334%;
  max-width: 70.83333333333334%;
}
.ant-col-push-17 {
  inset-inline-start: 70.83333333333334%;
}
.ant-col-pull-17 {
  inset-inline-end: 70.83333333333334%;
}
.ant-col-offset-17 {
  margin-inline-start: 70.83333333333334%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 66.66666666666666%;
  max-width: 66.66666666666666%;
}
.ant-col-push-16 {
  inset-inline-start: 66.66666666666666%;
}
.ant-col-pull-16 {
  inset-inline-end: 66.66666666666666%;
}
.ant-col-offset-16 {
  margin-inline-start: 66.66666666666666%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  inset-inline-start: 62.5%;
}
.ant-col-pull-15 {
  inset-inline-end: 62.5%;
}
.ant-col-offset-15 {
  margin-inline-start: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 58.333333333333336%;
  max-width: 58.333333333333336%;
}
.ant-col-push-14 {
  inset-inline-start: 58.333333333333336%;
}
.ant-col-pull-14 {
  inset-inline-end: 58.333333333333336%;
}
.ant-col-offset-14 {
  margin-inline-start: 58.333333333333336%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 54.166666666666664%;
  max-width: 54.166666666666664%;
}
.ant-col-push-13 {
  inset-inline-start: 54.166666666666664%;
}
.ant-col-pull-13 {
  inset-inline-end: 54.166666666666664%;
}
.ant-col-offset-13 {
  margin-inline-start: 54.166666666666664%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  inset-inline-start: 50%;
}
.ant-col-pull-12 {
  inset-inline-end: 50%;
}
.ant-col-offset-12 {
  margin-inline-start: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 45.83333333333333%;
  max-width: 45.83333333333333%;
}
.ant-col-push-11 {
  inset-inline-start: 45.83333333333333%;
}
.ant-col-pull-11 {
  inset-inline-end: 45.83333333333333%;
}
.ant-col-offset-11 {
  margin-inline-start: 45.83333333333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 41.66666666666667%;
  max-width: 41.66666666666667%;
}
.ant-col-push-10 {
  inset-inline-start: 41.66666666666667%;
}
.ant-col-pull-10 {
  inset-inline-end: 41.66666666666667%;
}
.ant-col-offset-10 {
  margin-inline-start: 41.66666666666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  inset-inline-start: 37.5%;
}
.ant-col-pull-9 {
  inset-inline-end: 37.5%;
}
.ant-col-offset-9 {
  margin-inline-start: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 33.33333333333333%;
  max-width: 33.33333333333333%;
}
.ant-col-push-8 {
  inset-inline-start: 33.33333333333333%;
}
.ant-col-pull-8 {
  inset-inline-end: 33.33333333333333%;
}
.ant-col-offset-8 {
  margin-inline-start: 33.33333333333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 29.166666666666668%;
  max-width: 29.166666666666668%;
}
.ant-col-push-7 {
  inset-inline-start: 29.166666666666668%;
}
.ant-col-pull-7 {
  inset-inline-end: 29.166666666666668%;
}
.ant-col-offset-7 {
  margin-inline-start: 29.166666666666668%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  inset-inline-start: 25%;
}
.ant-col-pull-6 {
  inset-inline-end: 25%;
}
.ant-col-offset-6 {
  margin-inline-start: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 20.833333333333336%;
  max-width: 20.833333333333336%;
}
.ant-col-push-5 {
  inset-inline-start: 20.833333333333336%;
}
.ant-col-pull-5 {
  inset-inline-end: 20.833333333333336%;
}
.ant-col-offset-5 {
  margin-inline-start: 20.833333333333336%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 16.666666666666664%;
  max-width: 16.666666666666664%;
}
.ant-col-push-4 {
  inset-inline-start: 16.666666666666664%;
}
.ant-col-pull-4 {
  inset-inline-end: 16.666666666666664%;
}
.ant-col-offset-4 {
  margin-inline-start: 16.666666666666664%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  inset-inline-start: 12.5%;
}
.ant-col-pull-3 {
  inset-inline-end: 12.5%;
}
.ant-col-offset-3 {
  margin-inline-start: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 8.333333333333332%;
  max-width: 8.333333333333332%;
}
.ant-col-push-2 {
  inset-inline-start: 8.333333333333332%;
}
.ant-col-pull-2 {
  inset-inline-end: 8.333333333333332%;
}
.ant-col-offset-2 {
  margin-inline-start: 8.333333333333332%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 4.166666666666666%;
  max-width: 4.166666666666666%;
}
.ant-col-push-1 {
  inset-inline-start: 4.166666666666666%;
}
.ant-col-pull-1 {
  inset-inline-end: 4.166666666666666%;
}
.ant-col-offset-1 {
  margin-inline-start: 4.166666666666666%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  inset-inline-start: auto;
}
.ant-col-pull-0 {
  inset-inline-end: auto;
}
.ant-col-offset-0 {
  margin-inline-start: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-flex {
  flex: var(--ant-col-flex);
}
.ant-col-xs-24 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  inset-inline-start: 100%;
}
.ant-col-xs-pull-24 {
  inset-inline-end: 100%;
}
.ant-col-xs-offset-24 {
  margin-inline-start: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 95.83333333333334%;
  max-width: 95.83333333333334%;
}
.ant-col-xs-push-23 {
  inset-inline-start: 95.83333333333334%;
}
.ant-col-xs-pull-23 {
  inset-inline-end: 95.83333333333334%;
}
.ant-col-xs-offset-23 {
  margin-inline-start: 95.83333333333334%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 91.66666666666666%;
  max-width: 91.66666666666666%;
}
.ant-col-xs-push-22 {
  inset-inline-start: 91.66666666666666%;
}
.ant-col-xs-pull-22 {
  inset-inline-end: 91.66666666666666%;
}
.ant-col-xs-offset-22 {
  margin-inline-start: 91.66666666666666%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  inset-inline-start: 87.5%;
}
.ant-col-xs-pull-21 {
  inset-inline-end: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-inline-start: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 83.33333333333334%;
  max-width: 83.33333333333334%;
}
.ant-col-xs-push-20 {
  inset-inline-start: 83.33333333333334%;
}
.ant-col-xs-pull-20 {
  inset-inline-end: 83.33333333333334%;
}
.ant-col-xs-offset-20 {
  margin-inline-start: 83.33333333333334%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 79.16666666666666%;
  max-width: 79.16666666666666%;
}
.ant-col-xs-push-19 {
  inset-inline-start: 79.16666666666666%;
}
.ant-col-xs-pull-19 {
  inset-inline-end: 79.16666666666666%;
}
.ant-col-xs-offset-19 {
  margin-inline-start: 79.16666666666666%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  inset-inline-start: 75%;
}
.ant-col-xs-pull-18 {
  inset-inline-end: 75%;
}
.ant-col-xs-offset-18 {
  margin-inline-start: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 70.83333333333334%;
  max-width: 70.83333333333334%;
}
.ant-col-xs-push-17 {
  inset-inline-start: 70.83333333333334%;
}
.ant-col-xs-pull-17 {
  inset-inline-end: 70.83333333333334%;
}
.ant-col-xs-offset-17 {
  margin-inline-start: 70.83333333333334%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 66.66666666666666%;
  max-width: 66.66666666666666%;
}
.ant-col-xs-push-16 {
  inset-inline-start: 66.66666666666666%;
}
.ant-col-xs-pull-16 {
  inset-inline-end: 66.66666666666666%;
}
.ant-col-xs-offset-16 {
  margin-inline-start: 66.66666666666666%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  inset-inline-start: 62.5%;
}
.ant-col-xs-pull-15 {
  inset-inline-end: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-inline-start: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 58.333333333333336%;
  max-width: 58.333333333333336%;
}
.ant-col-xs-push-14 {
  inset-inline-start: 58.333333333333336%;
}
.ant-col-xs-pull-14 {
  inset-inline-end: 58.333333333333336%;
}
.ant-col-xs-offset-14 {
  margin-inline-start: 58.333333333333336%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 54.166666666666664%;
  max-width: 54.166666666666664%;
}
.ant-col-xs-push-13 {
  inset-inline-start: 54.166666666666664%;
}
.ant-col-xs-pull-13 {
  inset-inline-end: 54.166666666666664%;
}
.ant-col-xs-offset-13 {
  margin-inline-start: 54.166666666666664%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  inset-inline-start: 50%;
}
.ant-col-xs-pull-12 {
  inset-inline-end: 50%;
}
.ant-col-xs-offset-12 {
  margin-inline-start: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 45.83333333333333%;
  max-width: 45.83333333333333%;
}
.ant-col-xs-push-11 {
  inset-inline-start: 45.83333333333333%;
}
.ant-col-xs-pull-11 {
  inset-inline-end: 45.83333333333333%;
}
.ant-col-xs-offset-11 {
  margin-inline-start: 45.83333333333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 41.66666666666667%;
  max-width: 41.66666666666667%;
}
.ant-col-xs-push-10 {
  inset-inline-start: 41.66666666666667%;
}
.ant-col-xs-pull-10 {
  inset-inline-end: 41.66666666666667%;
}
.ant-col-xs-offset-10 {
  margin-inline-start: 41.66666666666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  inset-inline-start: 37.5%;
}
.ant-col-xs-pull-9 {
  inset-inline-end: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-inline-start: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 33.33333333333333%;
  max-width: 33.33333333333333%;
}
.ant-col-xs-push-8 {
  inset-inline-start: 33.33333333333333%;
}
.ant-col-xs-pull-8 {
  inset-inline-end: 33.33333333333333%;
}
.ant-col-xs-offset-8 {
  margin-inline-start: 33.33333333333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 29.166666666666668%;
  max-width: 29.166666666666668%;
}
.ant-col-xs-push-7 {
  inset-inline-start: 29.166666666666668%;
}
.ant-col-xs-pull-7 {
  inset-inline-end: 29.166666666666668%;
}
.ant-col-xs-offset-7 {
  margin-inline-start: 29.166666666666668%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  inset-inline-start: 25%;
}
.ant-col-xs-pull-6 {
  inset-inline-end: 25%;
}
.ant-col-xs-offset-6 {
  margin-inline-start: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 20.833333333333336%;
  max-width: 20.833333333333336%;
}
.ant-col-xs-push-5 {
  inset-inline-start: 20.833333333333336%;
}
.ant-col-xs-pull-5 {
  inset-inline-end: 20.833333333333336%;
}
.ant-col-xs-offset-5 {
  margin-inline-start: 20.833333333333336%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 16.666666666666664%;
  max-width: 16.666666666666664%;
}
.ant-col-xs-push-4 {
  inset-inline-start: 16.666666666666664%;
}
.ant-col-xs-pull-4 {
  inset-inline-end: 16.666666666666664%;
}
.ant-col-xs-offset-4 {
  margin-inline-start: 16.666666666666664%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  inset-inline-start: 12.5%;
}
.ant-col-xs-pull-3 {
  inset-inline-end: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-inline-start: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 8.333333333333332%;
  max-width: 8.333333333333332%;
}
.ant-col-xs-push-2 {
  inset-inline-start: 8.333333333333332%;
}
.ant-col-xs-pull-2 {
  inset-inline-end: 8.333333333333332%;
}
.ant-col-xs-offset-2 {
  margin-inline-start: 8.333333333333332%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  --ant-display: block;
  display: block;
  display: var(--ant-display);
  flex: 0 0 4.166666666666666%;
  max-width: 4.166666666666666%;
}
.ant-col-xs-push-1 {
  inset-inline-start: 4.166666666666666%;
}
.ant-col-xs-pull-1 {
  inset-inline-end: 4.166666666666666%;
}
.ant-col-xs-offset-1 {
  margin-inline-start: 4.166666666666666%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  inset-inline-start: auto;
}
.ant-col-pull-0 {
  inset-inline-end: auto;
}
.ant-col-xs-push-0 {
  inset-inline-start: auto;
}
.ant-col-xs-pull-0 {
  inset-inline-end: auto;
}
.ant-col-xs-offset-0 {
  margin-inline-start: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-xs-flex {
  flex: var(--ant-col-xs-flex);
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    inset-inline-start: 100%;
  }
  .ant-col-sm-pull-24 {
    inset-inline-end: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-inline-start: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 95.83333333333334%;
    max-width: 95.83333333333334%;
  }
  .ant-col-sm-push-23 {
    inset-inline-start: 95.83333333333334%;
  }
  .ant-col-sm-pull-23 {
    inset-inline-end: 95.83333333333334%;
  }
  .ant-col-sm-offset-23 {
    margin-inline-start: 95.83333333333334%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .ant-col-sm-push-22 {
    inset-inline-start: 91.66666666666666%;
  }
  .ant-col-sm-pull-22 {
    inset-inline-end: 91.66666666666666%;
  }
  .ant-col-sm-offset-22 {
    margin-inline-start: 91.66666666666666%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    inset-inline-start: 87.5%;
  }
  .ant-col-sm-pull-21 {
    inset-inline-end: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-inline-start: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .ant-col-sm-push-20 {
    inset-inline-start: 83.33333333333334%;
  }
  .ant-col-sm-pull-20 {
    inset-inline-end: 83.33333333333334%;
  }
  .ant-col-sm-offset-20 {
    margin-inline-start: 83.33333333333334%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 79.16666666666666%;
    max-width: 79.16666666666666%;
  }
  .ant-col-sm-push-19 {
    inset-inline-start: 79.16666666666666%;
  }
  .ant-col-sm-pull-19 {
    inset-inline-end: 79.16666666666666%;
  }
  .ant-col-sm-offset-19 {
    margin-inline-start: 79.16666666666666%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    inset-inline-start: 75%;
  }
  .ant-col-sm-pull-18 {
    inset-inline-end: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-inline-start: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 70.83333333333334%;
    max-width: 70.83333333333334%;
  }
  .ant-col-sm-push-17 {
    inset-inline-start: 70.83333333333334%;
  }
  .ant-col-sm-pull-17 {
    inset-inline-end: 70.83333333333334%;
  }
  .ant-col-sm-offset-17 {
    margin-inline-start: 70.83333333333334%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .ant-col-sm-push-16 {
    inset-inline-start: 66.66666666666666%;
  }
  .ant-col-sm-pull-16 {
    inset-inline-end: 66.66666666666666%;
  }
  .ant-col-sm-offset-16 {
    margin-inline-start: 66.66666666666666%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    inset-inline-start: 62.5%;
  }
  .ant-col-sm-pull-15 {
    inset-inline-end: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-inline-start: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .ant-col-sm-push-14 {
    inset-inline-start: 58.333333333333336%;
  }
  .ant-col-sm-pull-14 {
    inset-inline-end: 58.333333333333336%;
  }
  .ant-col-sm-offset-14 {
    margin-inline-start: 58.333333333333336%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 54.166666666666664%;
    max-width: 54.166666666666664%;
  }
  .ant-col-sm-push-13 {
    inset-inline-start: 54.166666666666664%;
  }
  .ant-col-sm-pull-13 {
    inset-inline-end: 54.166666666666664%;
  }
  .ant-col-sm-offset-13 {
    margin-inline-start: 54.166666666666664%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    inset-inline-start: 50%;
  }
  .ant-col-sm-pull-12 {
    inset-inline-end: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-inline-start: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 45.83333333333333%;
    max-width: 45.83333333333333%;
  }
  .ant-col-sm-push-11 {
    inset-inline-start: 45.83333333333333%;
  }
  .ant-col-sm-pull-11 {
    inset-inline-end: 45.83333333333333%;
  }
  .ant-col-sm-offset-11 {
    margin-inline-start: 45.83333333333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .ant-col-sm-push-10 {
    inset-inline-start: 41.66666666666667%;
  }
  .ant-col-sm-pull-10 {
    inset-inline-end: 41.66666666666667%;
  }
  .ant-col-sm-offset-10 {
    margin-inline-start: 41.66666666666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    inset-inline-start: 37.5%;
  }
  .ant-col-sm-pull-9 {
    inset-inline-end: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-inline-start: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .ant-col-sm-push-8 {
    inset-inline-start: 33.33333333333333%;
  }
  .ant-col-sm-pull-8 {
    inset-inline-end: 33.33333333333333%;
  }
  .ant-col-sm-offset-8 {
    margin-inline-start: 33.33333333333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 29.166666666666668%;
    max-width: 29.166666666666668%;
  }
  .ant-col-sm-push-7 {
    inset-inline-start: 29.166666666666668%;
  }
  .ant-col-sm-pull-7 {
    inset-inline-end: 29.166666666666668%;
  }
  .ant-col-sm-offset-7 {
    margin-inline-start: 29.166666666666668%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    inset-inline-start: 25%;
  }
  .ant-col-sm-pull-6 {
    inset-inline-end: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-inline-start: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 20.833333333333336%;
    max-width: 20.833333333333336%;
  }
  .ant-col-sm-push-5 {
    inset-inline-start: 20.833333333333336%;
  }
  .ant-col-sm-pull-5 {
    inset-inline-end: 20.833333333333336%;
  }
  .ant-col-sm-offset-5 {
    margin-inline-start: 20.833333333333336%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .ant-col-sm-push-4 {
    inset-inline-start: 16.666666666666664%;
  }
  .ant-col-sm-pull-4 {
    inset-inline-end: 16.666666666666664%;
  }
  .ant-col-sm-offset-4 {
    margin-inline-start: 16.666666666666664%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    inset-inline-start: 12.5%;
  }
  .ant-col-sm-pull-3 {
    inset-inline-end: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-inline-start: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .ant-col-sm-push-2 {
    inset-inline-start: 8.333333333333332%;
  }
  .ant-col-sm-pull-2 {
    inset-inline-end: 8.333333333333332%;
  }
  .ant-col-sm-offset-2 {
    margin-inline-start: 8.333333333333332%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 4.166666666666666%;
    max-width: 4.166666666666666%;
  }
  .ant-col-sm-push-1 {
    inset-inline-start: 4.166666666666666%;
  }
  .ant-col-sm-pull-1 {
    inset-inline-end: 4.166666666666666%;
  }
  .ant-col-sm-offset-1 {
    margin-inline-start: 4.166666666666666%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-sm-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-sm-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-sm-offset-0 {
    margin-inline-start: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-sm-flex {
    flex: var(--ant-col-sm-flex);
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    inset-inline-start: 100%;
  }
  .ant-col-md-pull-24 {
    inset-inline-end: 100%;
  }
  .ant-col-md-offset-24 {
    margin-inline-start: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 95.83333333333334%;
    max-width: 95.83333333333334%;
  }
  .ant-col-md-push-23 {
    inset-inline-start: 95.83333333333334%;
  }
  .ant-col-md-pull-23 {
    inset-inline-end: 95.83333333333334%;
  }
  .ant-col-md-offset-23 {
    margin-inline-start: 95.83333333333334%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .ant-col-md-push-22 {
    inset-inline-start: 91.66666666666666%;
  }
  .ant-col-md-pull-22 {
    inset-inline-end: 91.66666666666666%;
  }
  .ant-col-md-offset-22 {
    margin-inline-start: 91.66666666666666%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    inset-inline-start: 87.5%;
  }
  .ant-col-md-pull-21 {
    inset-inline-end: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-inline-start: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .ant-col-md-push-20 {
    inset-inline-start: 83.33333333333334%;
  }
  .ant-col-md-pull-20 {
    inset-inline-end: 83.33333333333334%;
  }
  .ant-col-md-offset-20 {
    margin-inline-start: 83.33333333333334%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 79.16666666666666%;
    max-width: 79.16666666666666%;
  }
  .ant-col-md-push-19 {
    inset-inline-start: 79.16666666666666%;
  }
  .ant-col-md-pull-19 {
    inset-inline-end: 79.16666666666666%;
  }
  .ant-col-md-offset-19 {
    margin-inline-start: 79.16666666666666%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    inset-inline-start: 75%;
  }
  .ant-col-md-pull-18 {
    inset-inline-end: 75%;
  }
  .ant-col-md-offset-18 {
    margin-inline-start: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 70.83333333333334%;
    max-width: 70.83333333333334%;
  }
  .ant-col-md-push-17 {
    inset-inline-start: 70.83333333333334%;
  }
  .ant-col-md-pull-17 {
    inset-inline-end: 70.83333333333334%;
  }
  .ant-col-md-offset-17 {
    margin-inline-start: 70.83333333333334%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .ant-col-md-push-16 {
    inset-inline-start: 66.66666666666666%;
  }
  .ant-col-md-pull-16 {
    inset-inline-end: 66.66666666666666%;
  }
  .ant-col-md-offset-16 {
    margin-inline-start: 66.66666666666666%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    inset-inline-start: 62.5%;
  }
  .ant-col-md-pull-15 {
    inset-inline-end: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-inline-start: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .ant-col-md-push-14 {
    inset-inline-start: 58.333333333333336%;
  }
  .ant-col-md-pull-14 {
    inset-inline-end: 58.333333333333336%;
  }
  .ant-col-md-offset-14 {
    margin-inline-start: 58.333333333333336%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 54.166666666666664%;
    max-width: 54.166666666666664%;
  }
  .ant-col-md-push-13 {
    inset-inline-start: 54.166666666666664%;
  }
  .ant-col-md-pull-13 {
    inset-inline-end: 54.166666666666664%;
  }
  .ant-col-md-offset-13 {
    margin-inline-start: 54.166666666666664%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    inset-inline-start: 50%;
  }
  .ant-col-md-pull-12 {
    inset-inline-end: 50%;
  }
  .ant-col-md-offset-12 {
    margin-inline-start: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 45.83333333333333%;
    max-width: 45.83333333333333%;
  }
  .ant-col-md-push-11 {
    inset-inline-start: 45.83333333333333%;
  }
  .ant-col-md-pull-11 {
    inset-inline-end: 45.83333333333333%;
  }
  .ant-col-md-offset-11 {
    margin-inline-start: 45.83333333333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .ant-col-md-push-10 {
    inset-inline-start: 41.66666666666667%;
  }
  .ant-col-md-pull-10 {
    inset-inline-end: 41.66666666666667%;
  }
  .ant-col-md-offset-10 {
    margin-inline-start: 41.66666666666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    inset-inline-start: 37.5%;
  }
  .ant-col-md-pull-9 {
    inset-inline-end: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-inline-start: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .ant-col-md-push-8 {
    inset-inline-start: 33.33333333333333%;
  }
  .ant-col-md-pull-8 {
    inset-inline-end: 33.33333333333333%;
  }
  .ant-col-md-offset-8 {
    margin-inline-start: 33.33333333333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 29.166666666666668%;
    max-width: 29.166666666666668%;
  }
  .ant-col-md-push-7 {
    inset-inline-start: 29.166666666666668%;
  }
  .ant-col-md-pull-7 {
    inset-inline-end: 29.166666666666668%;
  }
  .ant-col-md-offset-7 {
    margin-inline-start: 29.166666666666668%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    inset-inline-start: 25%;
  }
  .ant-col-md-pull-6 {
    inset-inline-end: 25%;
  }
  .ant-col-md-offset-6 {
    margin-inline-start: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 20.833333333333336%;
    max-width: 20.833333333333336%;
  }
  .ant-col-md-push-5 {
    inset-inline-start: 20.833333333333336%;
  }
  .ant-col-md-pull-5 {
    inset-inline-end: 20.833333333333336%;
  }
  .ant-col-md-offset-5 {
    margin-inline-start: 20.833333333333336%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .ant-col-md-push-4 {
    inset-inline-start: 16.666666666666664%;
  }
  .ant-col-md-pull-4 {
    inset-inline-end: 16.666666666666664%;
  }
  .ant-col-md-offset-4 {
    margin-inline-start: 16.666666666666664%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    inset-inline-start: 12.5%;
  }
  .ant-col-md-pull-3 {
    inset-inline-end: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-inline-start: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .ant-col-md-push-2 {
    inset-inline-start: 8.333333333333332%;
  }
  .ant-col-md-pull-2 {
    inset-inline-end: 8.333333333333332%;
  }
  .ant-col-md-offset-2 {
    margin-inline-start: 8.333333333333332%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 4.166666666666666%;
    max-width: 4.166666666666666%;
  }
  .ant-col-md-push-1 {
    inset-inline-start: 4.166666666666666%;
  }
  .ant-col-md-pull-1 {
    inset-inline-end: 4.166666666666666%;
  }
  .ant-col-md-offset-1 {
    margin-inline-start: 4.166666666666666%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-md-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-md-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-md-offset-0 {
    margin-inline-start: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-md-flex {
    flex: var(--ant-col-md-flex);
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    inset-inline-start: 100%;
  }
  .ant-col-lg-pull-24 {
    inset-inline-end: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-inline-start: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 95.83333333333334%;
    max-width: 95.83333333333334%;
  }
  .ant-col-lg-push-23 {
    inset-inline-start: 95.83333333333334%;
  }
  .ant-col-lg-pull-23 {
    inset-inline-end: 95.83333333333334%;
  }
  .ant-col-lg-offset-23 {
    margin-inline-start: 95.83333333333334%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .ant-col-lg-push-22 {
    inset-inline-start: 91.66666666666666%;
  }
  .ant-col-lg-pull-22 {
    inset-inline-end: 91.66666666666666%;
  }
  .ant-col-lg-offset-22 {
    margin-inline-start: 91.66666666666666%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    inset-inline-start: 87.5%;
  }
  .ant-col-lg-pull-21 {
    inset-inline-end: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-inline-start: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .ant-col-lg-push-20 {
    inset-inline-start: 83.33333333333334%;
  }
  .ant-col-lg-pull-20 {
    inset-inline-end: 83.33333333333334%;
  }
  .ant-col-lg-offset-20 {
    margin-inline-start: 83.33333333333334%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 79.16666666666666%;
    max-width: 79.16666666666666%;
  }
  .ant-col-lg-push-19 {
    inset-inline-start: 79.16666666666666%;
  }
  .ant-col-lg-pull-19 {
    inset-inline-end: 79.16666666666666%;
  }
  .ant-col-lg-offset-19 {
    margin-inline-start: 79.16666666666666%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    inset-inline-start: 75%;
  }
  .ant-col-lg-pull-18 {
    inset-inline-end: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-inline-start: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 70.83333333333334%;
    max-width: 70.83333333333334%;
  }
  .ant-col-lg-push-17 {
    inset-inline-start: 70.83333333333334%;
  }
  .ant-col-lg-pull-17 {
    inset-inline-end: 70.83333333333334%;
  }
  .ant-col-lg-offset-17 {
    margin-inline-start: 70.83333333333334%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .ant-col-lg-push-16 {
    inset-inline-start: 66.66666666666666%;
  }
  .ant-col-lg-pull-16 {
    inset-inline-end: 66.66666666666666%;
  }
  .ant-col-lg-offset-16 {
    margin-inline-start: 66.66666666666666%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    inset-inline-start: 62.5%;
  }
  .ant-col-lg-pull-15 {
    inset-inline-end: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-inline-start: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .ant-col-lg-push-14 {
    inset-inline-start: 58.333333333333336%;
  }
  .ant-col-lg-pull-14 {
    inset-inline-end: 58.333333333333336%;
  }
  .ant-col-lg-offset-14 {
    margin-inline-start: 58.333333333333336%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 54.166666666666664%;
    max-width: 54.166666666666664%;
  }
  .ant-col-lg-push-13 {
    inset-inline-start: 54.166666666666664%;
  }
  .ant-col-lg-pull-13 {
    inset-inline-end: 54.166666666666664%;
  }
  .ant-col-lg-offset-13 {
    margin-inline-start: 54.166666666666664%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    inset-inline-start: 50%;
  }
  .ant-col-lg-pull-12 {
    inset-inline-end: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-inline-start: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 45.83333333333333%;
    max-width: 45.83333333333333%;
  }
  .ant-col-lg-push-11 {
    inset-inline-start: 45.83333333333333%;
  }
  .ant-col-lg-pull-11 {
    inset-inline-end: 45.83333333333333%;
  }
  .ant-col-lg-offset-11 {
    margin-inline-start: 45.83333333333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .ant-col-lg-push-10 {
    inset-inline-start: 41.66666666666667%;
  }
  .ant-col-lg-pull-10 {
    inset-inline-end: 41.66666666666667%;
  }
  .ant-col-lg-offset-10 {
    margin-inline-start: 41.66666666666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    inset-inline-start: 37.5%;
  }
  .ant-col-lg-pull-9 {
    inset-inline-end: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-inline-start: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .ant-col-lg-push-8 {
    inset-inline-start: 33.33333333333333%;
  }
  .ant-col-lg-pull-8 {
    inset-inline-end: 33.33333333333333%;
  }
  .ant-col-lg-offset-8 {
    margin-inline-start: 33.33333333333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 29.166666666666668%;
    max-width: 29.166666666666668%;
  }
  .ant-col-lg-push-7 {
    inset-inline-start: 29.166666666666668%;
  }
  .ant-col-lg-pull-7 {
    inset-inline-end: 29.166666666666668%;
  }
  .ant-col-lg-offset-7 {
    margin-inline-start: 29.166666666666668%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    inset-inline-start: 25%;
  }
  .ant-col-lg-pull-6 {
    inset-inline-end: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-inline-start: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 20.833333333333336%;
    max-width: 20.833333333333336%;
  }
  .ant-col-lg-push-5 {
    inset-inline-start: 20.833333333333336%;
  }
  .ant-col-lg-pull-5 {
    inset-inline-end: 20.833333333333336%;
  }
  .ant-col-lg-offset-5 {
    margin-inline-start: 20.833333333333336%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .ant-col-lg-push-4 {
    inset-inline-start: 16.666666666666664%;
  }
  .ant-col-lg-pull-4 {
    inset-inline-end: 16.666666666666664%;
  }
  .ant-col-lg-offset-4 {
    margin-inline-start: 16.666666666666664%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    inset-inline-start: 12.5%;
  }
  .ant-col-lg-pull-3 {
    inset-inline-end: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-inline-start: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .ant-col-lg-push-2 {
    inset-inline-start: 8.333333333333332%;
  }
  .ant-col-lg-pull-2 {
    inset-inline-end: 8.333333333333332%;
  }
  .ant-col-lg-offset-2 {
    margin-inline-start: 8.333333333333332%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 4.166666666666666%;
    max-width: 4.166666666666666%;
  }
  .ant-col-lg-push-1 {
    inset-inline-start: 4.166666666666666%;
  }
  .ant-col-lg-pull-1 {
    inset-inline-end: 4.166666666666666%;
  }
  .ant-col-lg-offset-1 {
    margin-inline-start: 4.166666666666666%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-lg-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-lg-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-lg-offset-0 {
    margin-inline-start: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-lg-flex {
    flex: var(--ant-col-lg-flex);
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    inset-inline-start: 100%;
  }
  .ant-col-xl-pull-24 {
    inset-inline-end: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-inline-start: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 95.83333333333334%;
    max-width: 95.83333333333334%;
  }
  .ant-col-xl-push-23 {
    inset-inline-start: 95.83333333333334%;
  }
  .ant-col-xl-pull-23 {
    inset-inline-end: 95.83333333333334%;
  }
  .ant-col-xl-offset-23 {
    margin-inline-start: 95.83333333333334%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .ant-col-xl-push-22 {
    inset-inline-start: 91.66666666666666%;
  }
  .ant-col-xl-pull-22 {
    inset-inline-end: 91.66666666666666%;
  }
  .ant-col-xl-offset-22 {
    margin-inline-start: 91.66666666666666%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    inset-inline-start: 87.5%;
  }
  .ant-col-xl-pull-21 {
    inset-inline-end: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-inline-start: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .ant-col-xl-push-20 {
    inset-inline-start: 83.33333333333334%;
  }
  .ant-col-xl-pull-20 {
    inset-inline-end: 83.33333333333334%;
  }
  .ant-col-xl-offset-20 {
    margin-inline-start: 83.33333333333334%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 79.16666666666666%;
    max-width: 79.16666666666666%;
  }
  .ant-col-xl-push-19 {
    inset-inline-start: 79.16666666666666%;
  }
  .ant-col-xl-pull-19 {
    inset-inline-end: 79.16666666666666%;
  }
  .ant-col-xl-offset-19 {
    margin-inline-start: 79.16666666666666%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    inset-inline-start: 75%;
  }
  .ant-col-xl-pull-18 {
    inset-inline-end: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-inline-start: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 70.83333333333334%;
    max-width: 70.83333333333334%;
  }
  .ant-col-xl-push-17 {
    inset-inline-start: 70.83333333333334%;
  }
  .ant-col-xl-pull-17 {
    inset-inline-end: 70.83333333333334%;
  }
  .ant-col-xl-offset-17 {
    margin-inline-start: 70.83333333333334%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .ant-col-xl-push-16 {
    inset-inline-start: 66.66666666666666%;
  }
  .ant-col-xl-pull-16 {
    inset-inline-end: 66.66666666666666%;
  }
  .ant-col-xl-offset-16 {
    margin-inline-start: 66.66666666666666%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    inset-inline-start: 62.5%;
  }
  .ant-col-xl-pull-15 {
    inset-inline-end: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-inline-start: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .ant-col-xl-push-14 {
    inset-inline-start: 58.333333333333336%;
  }
  .ant-col-xl-pull-14 {
    inset-inline-end: 58.333333333333336%;
  }
  .ant-col-xl-offset-14 {
    margin-inline-start: 58.333333333333336%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 54.166666666666664%;
    max-width: 54.166666666666664%;
  }
  .ant-col-xl-push-13 {
    inset-inline-start: 54.166666666666664%;
  }
  .ant-col-xl-pull-13 {
    inset-inline-end: 54.166666666666664%;
  }
  .ant-col-xl-offset-13 {
    margin-inline-start: 54.166666666666664%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    inset-inline-start: 50%;
  }
  .ant-col-xl-pull-12 {
    inset-inline-end: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-inline-start: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 45.83333333333333%;
    max-width: 45.83333333333333%;
  }
  .ant-col-xl-push-11 {
    inset-inline-start: 45.83333333333333%;
  }
  .ant-col-xl-pull-11 {
    inset-inline-end: 45.83333333333333%;
  }
  .ant-col-xl-offset-11 {
    margin-inline-start: 45.83333333333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .ant-col-xl-push-10 {
    inset-inline-start: 41.66666666666667%;
  }
  .ant-col-xl-pull-10 {
    inset-inline-end: 41.66666666666667%;
  }
  .ant-col-xl-offset-10 {
    margin-inline-start: 41.66666666666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    inset-inline-start: 37.5%;
  }
  .ant-col-xl-pull-9 {
    inset-inline-end: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-inline-start: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .ant-col-xl-push-8 {
    inset-inline-start: 33.33333333333333%;
  }
  .ant-col-xl-pull-8 {
    inset-inline-end: 33.33333333333333%;
  }
  .ant-col-xl-offset-8 {
    margin-inline-start: 33.33333333333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 29.166666666666668%;
    max-width: 29.166666666666668%;
  }
  .ant-col-xl-push-7 {
    inset-inline-start: 29.166666666666668%;
  }
  .ant-col-xl-pull-7 {
    inset-inline-end: 29.166666666666668%;
  }
  .ant-col-xl-offset-7 {
    margin-inline-start: 29.166666666666668%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    inset-inline-start: 25%;
  }
  .ant-col-xl-pull-6 {
    inset-inline-end: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-inline-start: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 20.833333333333336%;
    max-width: 20.833333333333336%;
  }
  .ant-col-xl-push-5 {
    inset-inline-start: 20.833333333333336%;
  }
  .ant-col-xl-pull-5 {
    inset-inline-end: 20.833333333333336%;
  }
  .ant-col-xl-offset-5 {
    margin-inline-start: 20.833333333333336%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .ant-col-xl-push-4 {
    inset-inline-start: 16.666666666666664%;
  }
  .ant-col-xl-pull-4 {
    inset-inline-end: 16.666666666666664%;
  }
  .ant-col-xl-offset-4 {
    margin-inline-start: 16.666666666666664%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    inset-inline-start: 12.5%;
  }
  .ant-col-xl-pull-3 {
    inset-inline-end: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-inline-start: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .ant-col-xl-push-2 {
    inset-inline-start: 8.333333333333332%;
  }
  .ant-col-xl-pull-2 {
    inset-inline-end: 8.333333333333332%;
  }
  .ant-col-xl-offset-2 {
    margin-inline-start: 8.333333333333332%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 4.166666666666666%;
    max-width: 4.166666666666666%;
  }
  .ant-col-xl-push-1 {
    inset-inline-start: 4.166666666666666%;
  }
  .ant-col-xl-pull-1 {
    inset-inline-end: 4.166666666666666%;
  }
  .ant-col-xl-offset-1 {
    margin-inline-start: 4.166666666666666%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-xl-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-xl-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-xl-offset-0 {
    margin-inline-start: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-xl-flex {
    flex: var(--ant-col-xl-flex);
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    inset-inline-start: 100%;
  }
  .ant-col-xxl-pull-24 {
    inset-inline-end: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-inline-start: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 95.83333333333334%;
    max-width: 95.83333333333334%;
  }
  .ant-col-xxl-push-23 {
    inset-inline-start: 95.83333333333334%;
  }
  .ant-col-xxl-pull-23 {
    inset-inline-end: 95.83333333333334%;
  }
  .ant-col-xxl-offset-23 {
    margin-inline-start: 95.83333333333334%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 91.66666666666666%;
    max-width: 91.66666666666666%;
  }
  .ant-col-xxl-push-22 {
    inset-inline-start: 91.66666666666666%;
  }
  .ant-col-xxl-pull-22 {
    inset-inline-end: 91.66666666666666%;
  }
  .ant-col-xxl-offset-22 {
    margin-inline-start: 91.66666666666666%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    inset-inline-start: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    inset-inline-end: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-inline-start: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 83.33333333333334%;
    max-width: 83.33333333333334%;
  }
  .ant-col-xxl-push-20 {
    inset-inline-start: 83.33333333333334%;
  }
  .ant-col-xxl-pull-20 {
    inset-inline-end: 83.33333333333334%;
  }
  .ant-col-xxl-offset-20 {
    margin-inline-start: 83.33333333333334%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 79.16666666666666%;
    max-width: 79.16666666666666%;
  }
  .ant-col-xxl-push-19 {
    inset-inline-start: 79.16666666666666%;
  }
  .ant-col-xxl-pull-19 {
    inset-inline-end: 79.16666666666666%;
  }
  .ant-col-xxl-offset-19 {
    margin-inline-start: 79.16666666666666%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    inset-inline-start: 75%;
  }
  .ant-col-xxl-pull-18 {
    inset-inline-end: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-inline-start: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 70.83333333333334%;
    max-width: 70.83333333333334%;
  }
  .ant-col-xxl-push-17 {
    inset-inline-start: 70.83333333333334%;
  }
  .ant-col-xxl-pull-17 {
    inset-inline-end: 70.83333333333334%;
  }
  .ant-col-xxl-offset-17 {
    margin-inline-start: 70.83333333333334%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 66.66666666666666%;
    max-width: 66.66666666666666%;
  }
  .ant-col-xxl-push-16 {
    inset-inline-start: 66.66666666666666%;
  }
  .ant-col-xxl-pull-16 {
    inset-inline-end: 66.66666666666666%;
  }
  .ant-col-xxl-offset-16 {
    margin-inline-start: 66.66666666666666%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    inset-inline-start: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    inset-inline-end: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-inline-start: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 58.333333333333336%;
    max-width: 58.333333333333336%;
  }
  .ant-col-xxl-push-14 {
    inset-inline-start: 58.333333333333336%;
  }
  .ant-col-xxl-pull-14 {
    inset-inline-end: 58.333333333333336%;
  }
  .ant-col-xxl-offset-14 {
    margin-inline-start: 58.333333333333336%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 54.166666666666664%;
    max-width: 54.166666666666664%;
  }
  .ant-col-xxl-push-13 {
    inset-inline-start: 54.166666666666664%;
  }
  .ant-col-xxl-pull-13 {
    inset-inline-end: 54.166666666666664%;
  }
  .ant-col-xxl-offset-13 {
    margin-inline-start: 54.166666666666664%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    inset-inline-start: 50%;
  }
  .ant-col-xxl-pull-12 {
    inset-inline-end: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-inline-start: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 45.83333333333333%;
    max-width: 45.83333333333333%;
  }
  .ant-col-xxl-push-11 {
    inset-inline-start: 45.83333333333333%;
  }
  .ant-col-xxl-pull-11 {
    inset-inline-end: 45.83333333333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-inline-start: 45.83333333333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }
  .ant-col-xxl-push-10 {
    inset-inline-start: 41.66666666666667%;
  }
  .ant-col-xxl-pull-10 {
    inset-inline-end: 41.66666666666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-inline-start: 41.66666666666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    inset-inline-start: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    inset-inline-end: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-inline-start: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 33.33333333333333%;
    max-width: 33.33333333333333%;
  }
  .ant-col-xxl-push-8 {
    inset-inline-start: 33.33333333333333%;
  }
  .ant-col-xxl-pull-8 {
    inset-inline-end: 33.33333333333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-inline-start: 33.33333333333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 29.166666666666668%;
    max-width: 29.166666666666668%;
  }
  .ant-col-xxl-push-7 {
    inset-inline-start: 29.166666666666668%;
  }
  .ant-col-xxl-pull-7 {
    inset-inline-end: 29.166666666666668%;
  }
  .ant-col-xxl-offset-7 {
    margin-inline-start: 29.166666666666668%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    inset-inline-start: 25%;
  }
  .ant-col-xxl-pull-6 {
    inset-inline-end: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-inline-start: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 20.833333333333336%;
    max-width: 20.833333333333336%;
  }
  .ant-col-xxl-push-5 {
    inset-inline-start: 20.833333333333336%;
  }
  .ant-col-xxl-pull-5 {
    inset-inline-end: 20.833333333333336%;
  }
  .ant-col-xxl-offset-5 {
    margin-inline-start: 20.833333333333336%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 16.666666666666664%;
    max-width: 16.666666666666664%;
  }
  .ant-col-xxl-push-4 {
    inset-inline-start: 16.666666666666664%;
  }
  .ant-col-xxl-pull-4 {
    inset-inline-end: 16.666666666666664%;
  }
  .ant-col-xxl-offset-4 {
    margin-inline-start: 16.666666666666664%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    inset-inline-start: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    inset-inline-end: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-inline-start: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 8.333333333333332%;
    max-width: 8.333333333333332%;
  }
  .ant-col-xxl-push-2 {
    inset-inline-start: 8.333333333333332%;
  }
  .ant-col-xxl-pull-2 {
    inset-inline-end: 8.333333333333332%;
  }
  .ant-col-xxl-offset-2 {
    margin-inline-start: 8.333333333333332%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 4.166666666666666%;
    max-width: 4.166666666666666%;
  }
  .ant-col-xxl-push-1 {
    inset-inline-start: 4.166666666666666%;
  }
  .ant-col-xxl-pull-1 {
    inset-inline-end: 4.166666666666666%;
  }
  .ant-col-xxl-offset-1 {
    margin-inline-start: 4.166666666666666%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-xxl-push-0 {
    inset-inline-start: auto;
  }
  .ant-col-xxl-pull-0 {
    inset-inline-end: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-inline-start: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-xxl-flex {
    flex: var(--ant-col-xxl-flex);
  }
}
.ant-btn {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-btn::before,
.ant-btn::after {
  box-sizing: border-box;
}
.ant-btn [class^="ant-btn"],
.ant-btn [class*=" ant-btn"] {
  box-sizing: border-box;
}
.ant-btn [class^="ant-btn"]::before,
.ant-btn [class*=" ant-btn"]::before,
.ant-btn [class^="ant-btn"]::after,
.ant-btn [class*=" ant-btn"]::after {
  box-sizing: border-box;
}
.ant-btn {
  outline: 0;
  position: relative;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  color: rgba(0, 0, 0, 0.88);
}
.ant-btn:disabled > * {
  pointer-events: none;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn .ant-btn-icon {
  line-height: 1;
}
.ant-btn > a {
  color: currentColor;
}
.ant-btn:not(:disabled):focus-visible {
  outline: 4px solid #b7eb8f;
  outline-offset: 1px;
  transition:
    outline-offset 0,
    outline 0;
}
.ant-btn.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-inline-end: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-icon-end {
  flex-direction: row-reverse;
}
.ant-btn {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 32px;
  padding: 4px 15px;
  border-radius: 16px;
}
.ant-btn.ant-btn-icon-only {
  width: 32px;
  padding-inline: 0;
}
.ant-btn.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn.ant-btn-icon-only.ant-btn-round {
  width: auto;
}
.ant-btn.ant-btn-icon-only .anticon {
  font-size: 16px;
}
.ant-btn.ant-btn-loading {
  opacity: 0.65;
  cursor: default;
}
.ant-btn .ant-btn-loading-icon {
  transition:
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn.ant-btn-circle.ant-btn {
  min-width: 32px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
}
.ant-btn.ant-btn-round.ant-btn {
  border-radius: 32px;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}
.ant-btn-sm {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 24px;
  padding: 0 7px;
  border-radius: 8px;
}
.ant-btn-sm.ant-btn-icon-only {
  width: 24px;
  padding-inline: 0;
}
.ant-btn-sm.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-sm.ant-btn-icon-only.ant-btn-round {
  width: auto;
}
.ant-btn-sm.ant-btn-icon-only .anticon {
  font-size: 14px;
}
.ant-btn-sm.ant-btn-loading {
  opacity: 0.65;
  cursor: default;
}
.ant-btn-sm .ant-btn-loading-icon {
  transition:
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
}
.ant-btn.ant-btn-round.ant-btn-sm {
  border-radius: 24px;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}
.ant-btn-lg {
  font-size: 16px;
  line-height: 1.5;
  height: 40px;
  padding: 7px 15px;
  border-radius: 16px;
}
.ant-btn-lg.ant-btn-icon-only {
  width: 40px;
  padding-inline: 0;
}
.ant-btn-lg.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-lg.ant-btn-icon-only.ant-btn-round {
  width: auto;
}
.ant-btn-lg.ant-btn-icon-only .anticon {
  font-size: 18px;
}
.ant-btn-lg.ant-btn-loading {
  opacity: 0.65;
  cursor: default;
}
.ant-btn-lg .ant-btn-loading-icon {
  transition:
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 50%;
}
.ant-btn.ant-btn-round.ant-btn-lg {
  border-radius: 40px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn-default {
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.ant-btn-default.ant-btn-solid {
  color: #fff;
  background: #000;
}
.ant-btn-default.ant-btn-solid:disabled,
.ant-btn-default.ant-btn-solid.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-default.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: rgba(0, 0, 0, 0.75);
}
.ant-btn-default.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):active {
  background: rgba(0, 0, 0, 0.95);
}
.ant-btn-default.ant-btn-outlined,
.ant-btn-default.ant-btn-dashed {
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn-default.ant-btn-outlined:disabled,
.ant-btn-default.ant-btn-dashed:disabled,
.ant-btn-default.ant-btn-outlined.ant-btn-disabled,
.ant-btn-default.ant-btn-dashed.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-default.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #73d13d;
  border-color: #73d13d;
  background: #fff;
}
.ant-btn-default.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-default.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):active {
  color: #389e0d;
  border-color: #389e0d;
  background: #fff;
}
.ant-btn-default.ant-btn-dashed {
  border-style: dashed;
}
.ant-btn-default.ant-btn-filled {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.04);
}
.ant-btn-default.ant-btn-filled:disabled,
.ant-btn-default.ant-btn-filled.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-default.ant-btn-filled:not(:disabled):not(.ant-btn-disabled):hover {
  background: rgba(0, 0, 0, 0.06);
}
.ant-btn-default.ant-btn-filled:not(:disabled):not(.ant-btn-disabled):active {
  background: rgba(0, 0, 0, 0.15);
}
.ant-btn-default.ant-btn-text {
  color: rgba(0, 0, 0, 0.88);
  box-shadow: none;
}
.ant-btn-default.ant-btn-text:disabled,
.ant-btn-default.ant-btn-text.ant-btn-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn-default.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: rgba(0, 0, 0, 0.88);
  background: rgba(0, 0, 0, 0.06);
}
.ant-btn-default.ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
  color: rgba(0, 0, 0, 0.88);
  background: rgba(0, 0, 0, 0.15);
}
.ant-btn-default.ant-btn-link {
  color: rgba(0, 0, 0, 0.88);
  box-shadow: none;
}
.ant-btn-default.ant-btn-link:disabled,
.ant-btn-default.ant-btn-link.ant-btn-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn-default.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #69b1ff;
  background: transparent;
}
.ant-btn-default.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
  color: #0958d9;
}
.ant-btn-default.ant-btn-background-ghost {
  color: #fff;
  background: transparent;
  border-color: #fff;
  box-shadow: none;
}
.ant-btn-default.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):hover {
  background: transparent;
}
.ant-btn-default.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):active {
  background: transparent;
}
.ant-btn-default.ant-btn-background-ghost:disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
.ant-btn-primary {
  height: 40px;
  color: #fff;
  background: #0068d7;
  box-shadow: 0 2px 0 rgba(142, 255, 30, 0.08);
}
.ant-btn-primary.ant-btn-solid {
  color: #fff;
  background: #52c41a;
}
.ant-btn-primary.ant-btn-solid:disabled,
.ant-btn-primary.ant-btn-solid.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-primary.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: #73d13d;
}
.ant-btn-primary.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):active {
  background: #389e0d;
}
.ant-btn-primary.ant-btn-outlined,
.ant-btn-primary.ant-btn-dashed {
  border-color: #52c41a;
  background: #fff;
}
.ant-btn-primary.ant-btn-outlined:disabled,
.ant-btn-primary.ant-btn-dashed:disabled,
.ant-btn-primary.ant-btn-outlined.ant-btn-disabled,
.ant-btn-primary.ant-btn-dashed.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-primary.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-primary.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #73d13d;
  border-color: #73d13d;
  background: #fff;
}
.ant-btn-primary.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-primary.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):active {
  color: #389e0d;
  border-color: #389e0d;
  background: #fff;
}
.ant-btn-primary.ant-btn-dashed {
  border-style: dashed;
}
.ant-btn-primary.ant-btn-filled {
  box-shadow: none;
  background: #f6ffed;
}
.ant-btn-primary.ant-btn-filled:disabled,
.ant-btn-primary.ant-btn-filled.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-primary.ant-btn-filled:not(:disabled):not(.ant-btn-disabled):hover {
  background: #d9f7be;
}
.ant-btn-primary.ant-btn-filled:not(:disabled):not(.ant-btn-disabled):active {
  background: #b7eb8f;
}
.ant-btn-primary.ant-btn-text {
  color: #52c41a;
  box-shadow: none;
}
.ant-btn-primary.ant-btn-text:disabled,
.ant-btn-primary.ant-btn-text.ant-btn-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn-primary.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: #73d13d;
  background: #f6ffed;
}
.ant-btn-primary.ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
  color: #389e0d;
  background: #b7eb8f;
}
.ant-btn-primary.ant-btn-link {
  color: #52c41a;
  box-shadow: none;
}
.ant-btn-primary.ant-btn-link:disabled,
.ant-btn-primary.ant-btn-link.ant-btn-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn-primary.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #73d13d;
  background: transparent;
}
.ant-btn-primary.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
  color: #389e0d;
}
.ant-btn-primary.ant-btn-background-ghost {
  color: #52c41a;
  background: transparent;
  border-color: #52c41a;
  box-shadow: none;
}
.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):hover {
  background: transparent;
  color: #73d13d;
  border-color: #73d13d;
}
.ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):active {
  background: transparent;
  color: #389e0d;
  border-color: #389e0d;
}
.ant-btn-primary.ant-btn-background-ghost:disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
.ant-btn-dangerous {
  color: #ff4d4f;
  box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06);
}
.ant-btn-dangerous.ant-btn-solid {
  color: #fff;
  background: #ff4d4f;
}
.ant-btn-dangerous.ant-btn-solid:disabled,
.ant-btn-dangerous.ant-btn-solid.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: #ff7875;
}
.ant-btn-dangerous.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):active {
  background: #d9363e;
}
.ant-btn-dangerous.ant-btn-outlined,
.ant-btn-dangerous.ant-btn-dashed {
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous.ant-btn-outlined:disabled,
.ant-btn-dangerous.ant-btn-dashed:disabled,
.ant-btn-dangerous.ant-btn-outlined.ant-btn-disabled,
.ant-btn-dangerous.ant-btn-dashed.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-dangerous.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ff7875;
  border-color: #ffa39e;
}
.ant-btn-dangerous.ant-btn-outlined:not(:disabled):not(.ant-btn-disabled):active,
.ant-btn-dangerous.ant-btn-dashed:not(:disabled):not(.ant-btn-disabled):active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-dangerous.ant-btn-dashed {
  border-style: dashed;
}
.ant-btn-dangerous.ant-btn-filled {
  box-shadow: none;
  background: #fff2f0;
}
.ant-btn-dangerous.ant-btn-filled:disabled,
.ant-btn-dangerous.ant-btn-filled.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-filled:not(:disabled):not(.ant-btn-disabled):hover {
  background: #ffdfdc;
}
.ant-btn-dangerous.ant-btn-filled:not(:disabled):not(.ant-btn-disabled):active {
  background: #ffccc7;
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text:disabled,
.ant-btn-dangerous.ant-btn-text.ant-btn-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn-dangerous.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ff7875;
  background: #fff2f0;
}
.ant-btn-dangerous.ant-btn-text:not(:disabled):not(.ant-btn-disabled):active {
  color: #ff7875;
  background: #ffccc7;
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link:disabled,
.ant-btn-dangerous.ant-btn-link.ant-btn-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-btn-dangerous.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
  color: #ff7875;
}
.ant-btn-dangerous.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
  color: #d9363e;
}
.ant-btn-dangerous.ant-btn-background-ghost {
  color: #ff4d4f;
  background: transparent;
  border-color: #ff4d4f;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):hover {
  background: transparent;
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-dangerous.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):active {
  background: transparent;
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-dangerous.ant-btn-background-ghost:disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > span:not(:last-child),
.ant-btn-group > .ant-btn:not(:last-child),
.ant-btn-group > span:not(:last-child) > .ant-btn,
.ant-btn-group > .ant-btn:not(:last-child) > .ant-btn {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.ant-btn-group > span:not(:first-child),
.ant-btn-group > .ant-btn:not(:first-child) {
  margin-inline-start: -1px;
}
.ant-btn-group > span:not(:first-child),
.ant-btn-group > .ant-btn:not(:first-child),
.ant-btn-group > span:not(:first-child) > .ant-btn,
.ant-btn-group > .ant-btn:not(:first-child) > .ant-btn {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.ant-btn-group .ant-btn {
  position: relative;
  z-index: 1;
}
.ant-btn-group .ant-btn:hover,
.ant-btn-group .ant-btn:focus,
.ant-btn-group .ant-btn:active {
  z-index: 2;
}
.ant-btn-group .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group > span:not(:last-child):not(:disabled),
.ant-btn-group > .ant-btn-primary:not(:last-child):not(:disabled),
.ant-btn-group > span:not(:last-child) > .ant-btn-primary:not(:disabled),
.ant-btn-group > .ant-btn-primary:not(:last-child) > .ant-btn-primary:not(:disabled) {
  border-inline-end-color: #73d13d;
}
.ant-btn-group > span:not(:first-child):not(:disabled),
.ant-btn-group > .ant-btn-primary:not(:first-child):not(:disabled),
.ant-btn-group > span:not(:first-child) > .ant-btn-primary:not(:disabled),
.ant-btn-group > .ant-btn-primary:not(:first-child) > .ant-btn-primary:not(:disabled) {
  border-inline-start-color: #73d13d;
}
.ant-btn-group > span:not(:last-child):not(:disabled),
.ant-btn-group > .ant-btn-danger:not(:last-child):not(:disabled),
.ant-btn-group > span:not(:last-child) > .ant-btn-danger:not(:disabled),
.ant-btn-group > .ant-btn-danger:not(:last-child) > .ant-btn-danger:not(:disabled) {
  border-inline-end-color: #ff7875;
}
.ant-btn-group > span:not(:first-child):not(:disabled),
.ant-btn-group > .ant-btn-danger:not(:first-child):not(:disabled),
.ant-btn-group > span:not(:first-child) > .ant-btn-danger:not(:disabled),
.ant-btn-group > .ant-btn-danger:not(:first-child) > .ant-btn-danger:not(:disabled) {
  border-inline-start-color: #ff7875;
}
.ant-upload-wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-upload-wrapper::before,
.ant-upload-wrapper::after {
  box-sizing: border-box;
}
.ant-upload-wrapper [class^="ant-upload"],
.ant-upload-wrapper [class*=" ant-upload"] {
  box-sizing: border-box;
}
.ant-upload-wrapper [class^="ant-upload"]::before,
.ant-upload-wrapper [class*=" ant-upload"]::before,
.ant-upload-wrapper [class^="ant-upload"]::after,
.ant-upload-wrapper [class*=" ant-upload"]::after {
  box-sizing: border-box;
}
.ant-upload-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}
.ant-upload-wrapper .ant-upload {
  outline: 0;
}
.ant-upload-wrapper .ant-upload input[type="file"] {
  cursor: pointer;
}
.ant-upload-wrapper .ant-upload-select {
  display: inline-block;
}
.ant-upload-wrapper .ant-upload-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-upload-wrapper .ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 16px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 16px;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  display: table;
  width: 100%;
  height: 100%;
  outline: 0;
  border-radius: 16px;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn:focus-visible {
  outline: 4px solid #b7eb8f;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover,
.ant-upload-wrapper .ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #73d13d;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 16px;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #52c41a;
  font-size: 48px;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-wrapper .ant-upload-drag.ant-upload-disabled p.ant-upload-drag-icon .anticon,
.ant-upload-wrapper .ant-upload-drag.ant-upload-disabled p.ant-upload-text,
.ant-upload-wrapper .ant-upload-drag.ant-upload-disabled p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.25);
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item:hover,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  flex: none;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  .anticon,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  .anticon,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  .anticon {
  font-size: 30px;
  color: #52c41a;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  img,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  img,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail
  img {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item
  .ant-upload-list-item-progress,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item
  .ant-upload-list-item-progress,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item
  .ant-upload-list-item-progress {
  bottom: 12px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-inline-start: 56px;
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill="#e6f4ff"],
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill="#e6f4ff"],
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill="#e6f4ff"] {
  fill: #fff2f0;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill="#1677ff"],
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill="#1677ff"],
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-error
  .ant-upload-list-item-thumbnail
  .anticon
  svg
  path[fill="#1677ff"] {
  fill: #ff4d4f;
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-uploading,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-uploading
  .ant-upload-list-item-name,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .ant-upload-list-item-name,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item::before,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item
  .ant-upload-list-item-thumbnail {
  border-radius: 50%;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  display: block;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper::before {
  display: table;
  content: "";
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper::after,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper::after {
  display: table;
  clear: both;
  content: "";
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 102px;
  height: 102px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 16px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select > .ant-upload,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #52c41a;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle {
  display: flex;
  flex-wrap: wrap;
}
@supports not (gap: 1px) {
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    > *,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-card
    > *,
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    > *,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle
    > * {
    margin-block-end: 8px;
    margin-inline-end: 8px;
  }
}
@supports (gap: 1px) {
  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-card,
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload-list.ant-upload-list-picture-circle,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload-list.ant-upload-list-picture-circle {
    gap: 8px;
  }
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-container,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-container {
  display: inline-block;
  width: 102px;
  height: 102px;
  vertical-align: top;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card::after,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card::after,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle::after,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle::after {
  display: none;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle::before {
  display: none;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before {
  position: absolute;
  z-index: 1;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item:hover::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item:hover::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item:hover::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item:hover::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item:hover
  .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item:hover
  .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item:hover
  .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item:hover
  .ant-upload-list-item-actions {
  opacity: 1;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions {
  position: absolute;
  inset-inline-start: 0;
  z-index: 10;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
  color: #fff;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye:hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye:hover,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-eye:hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-eye:hover,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-download:hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-download:hover,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-download:hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-download:hover,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-delete:hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-delete:hover,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-delete:hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-delete:hover {
  color: #fff;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye
  svg,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-eye
  svg,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-eye
  svg,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-eye
  svg,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-download
  svg,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-download
  svg,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-download
  svg,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-download
  svg,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-delete
  svg,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-actions
  .anticon-delete
  svg,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-delete
  svg,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-actions
  .anticon-delete
  svg {
  vertical-align: baseline;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-thumbnail,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-thumbnail,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-thumbnail
  img,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-thumbnail
  img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-name,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-name,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-name,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-name {
  display: none;
  text-align: center;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-file
  + .ant-upload-list-item-name,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-file
  + .ant-upload-list-item-name,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-file
  + .ant-upload-list-item-name,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-file
  + .ant-upload-list-item-name {
  position: absolute;
  bottom: 16px;
  display: block;
  width: calc(100% - 16px);
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading.ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading.ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading.ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: rgba(0, 0, 0, 0.02);
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading::before,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading::before,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .anticon-eye,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .anticon-download,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-uploading
  .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .anticon-delete,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-uploading
  .anticon-delete {
  display: none;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-progress,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-progress,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-progress,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 16px);
  padding-inline-start: 0;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  border-radius: 50%;
}
.ant-upload-wrapper .ant-upload-list {
  line-height: 1.5714285714285714;
}
.ant-upload-wrapper .ant-upload-list::before {
  display: table;
  content: "";
}
.ant-upload-wrapper .ant-upload-list::after {
  display: table;
  clear: both;
  content: "";
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 8px;
  line-height: 1.5714285714285714;
  flex: auto;
  transition: all 0.3s;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions {
  white-space: nowrap;
}
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action {
  opacity: 0;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action:focus-visible,
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions.picture
  .ant-upload-list-item-action {
  opacity: 1;
}
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action.ant-btn {
  height: 20px;
  border: 0;
  line-height: 1;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-inline-start: 22px;
  font-size: 14px;
  line-height: 0;
  pointer-events: none;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-progress > div {
  margin: 0;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-action {
  opacity: 1;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error {
  color: #ff4d4f;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
  color: #ff4d4f;
}
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-actions
  .anticon,
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-actions
  .anticon:hover {
  color: #ff4d4f;
}
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-actions
  .ant-upload-list-item-action {
  opacity: 1;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
  transition:
    opacity 0.3s,
    height 0.3s;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container::before {
  display: table;
  width: 0;
  height: 0;
  content: "";
}
.ant-upload-wrapper .ant-upload-animate-inline-appear,
.ant-upload-wrapper .ant-upload-animate-inline-enter,
.ant-upload-wrapper .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-fill-mode: forwards;
}
.ant-upload-wrapper .ant-upload-animate-inline-appear,
.ant-upload-wrapper .ant-upload-animate-inline-enter {
  animation-name: css-dev-only-do-not-override-5gkde5-uploadAnimateInlineIn;
}
.ant-upload-wrapper .ant-upload-animate-inline-leave {
  animation-name: css-dev-only-do-not-override-5gkde5-uploadAnimateInlineOut;
}
.ant-upload-wrapper .ant-fade-enter,
.ant-upload-wrapper .ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-upload-wrapper .ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-upload-wrapper .ant-fade-enter.ant-fade-enter-active,
.ant-upload-wrapper .ant-fade-appear.ant-fade-appear-active {
  animation-name: css-dev-only-do-not-override-5gkde5-antFadeIn;
  animation-play-state: running;
}
.ant-upload-wrapper .ant-fade-leave.ant-fade-leave-active {
  animation-name: css-dev-only-do-not-override-5gkde5-antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-upload-wrapper .ant-fade-enter,
.ant-upload-wrapper .ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-upload-wrapper .ant-fade-leave {
  animation-timing-function: linear;
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload .ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-upload .ant-motion-collapse-legacy-active {
  transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-upload .ant-motion-collapse {
  overflow: hidden;
  transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-flex {
  display: flex;
}
.ant-flex-vertical {
  flex-direction: column;
}
.ant-flex-rtl {
  direction: rtl;
}
.ant-flex:empty {
  display: none;
}
.ant-flex-gap-small {
  gap: 8px;
}
.ant-flex-gap-middle {
  gap: 16px;
}
.ant-flex-gap-large {
  gap: 24px;
}
.ant-flex-wrap-wrap {
  flex-wrap: wrap;
}
.ant-flex-wrap-nowrap {
  flex-wrap: nowrap;
}
.ant-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.ant-flex-align-center {
  align-items: center;
}
.ant-flex-align-start {
  align-items: start;
}
.ant-flex-align-end {
  align-items: end;
}
.ant-flex-align-flex-start {
  align-items: flex-start;
}
.ant-flex-align-flex-end {
  align-items: flex-end;
}
.ant-flex-align-self-start {
  align-items: self-start;
}
.ant-flex-align-self-end {
  align-items: self-end;
}
.ant-flex-align-baseline {
  align-items: baseline;
}
.ant-flex-align-normal {
  align-items: normal;
}
.ant-flex-align-stretch {
  align-items: stretch;
}
.ant-flex-justify-flex-start {
  justify-content: flex-start;
}
.ant-flex-justify-flex-end {
  justify-content: flex-end;
}
.ant-flex-justify-start {
  justify-content: start;
}
.ant-flex-justify-end {
  justify-content: end;
}
.ant-flex-justify-center {
  justify-content: center;
}
.ant-flex-justify-space-between {
  justify-content: space-between;
}
.ant-flex-justify-space-around {
  justify-content: space-around;
}
.ant-flex-justify-space-evenly {
  justify-content: space-evenly;
}
.ant-flex-justify-stretch {
  justify-content: stretch;
}
.ant-flex-justify-normal {
  justify-content: normal;
}
.ant-flex-justify-left {
  justify-content: left;
}
.ant-flex-justify-right {
  justify-content: right;
}
[class^="ant-table"],
[class*=" ant-table"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
[class^="ant-table"]::before,
[class*=" ant-table"]::before,
[class^="ant-table"]::after,
[class*=" ant-table"]::after {
  box-sizing: border-box;
}
[class^="ant-table"] [class^="ant-table"],
[class*=" ant-table"] [class^="ant-table"],
[class^="ant-table"] [class*=" ant-table"],
[class*=" ant-table"] [class*=" ant-table"] {
  box-sizing: border-box;
}
[class^="ant-table"] [class^="ant-table"]::before,
[class*=" ant-table"] [class^="ant-table"]::before,
[class^="ant-table"] [class*=" ant-table"]::before,
[class*=" ant-table"] [class*=" ant-table"]::before,
[class^="ant-table"] [class^="ant-table"]::after,
[class*=" ant-table"] [class^="ant-table"]::after,
[class^="ant-table"] [class*=" ant-table"]::after,
[class*=" ant-table"] [class*=" ant-table"]::after {
  box-sizing: border-box;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: "";
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: "";
}
.ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  background: #fff;
  border-radius: 16px 16px 0 0;
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(5, 5, 5, 0.06);
}
.ant-table-wrapper table {
  width: 100%;
  text-align: start;
  border-radius: 16px 16px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-wrapper .ant-table-cell,
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td,
.ant-table-wrapper tfoot > tr > th,
.ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-wrapper .ant-table-title {
  padding: 16px 16px;
}
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}
.ant-table-wrapper .ant-table-thead > tr > th[colspan]:not([colspan="1"]),
.ant-table-wrapper .ant-table-thead > tr > td[colspan]:not([colspan="1"]) {
  text-align: center;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  width: 1px;
  height: 1.6em;
  background-color: #f0f0f0;
  transform: translateY(-50%);
  transition: background-color 0.2s;
  content: "";
}
.ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  transition:
    background 0.2s,
    border-color 0.2s;
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-wrapper .ant-table-tbody > tr > th > .ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin-block: -16px;
  margin-inline: 32px -16px;
}
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td {
  border-bottom: 0;
}
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:first-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:first-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:first-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:first-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:last-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:last-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:last-child,
.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table
  .ant-table-tbody
  > tr:last-child
  > td:last-child {
  border-radius: 0;
}
.ant-table-wrapper .ant-table-tbody > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s ease;
}
.ant-table-wrapper .ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.88);
  background: #fafafa;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-wrapper .ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}
.ant-table-wrapper .ant-table-pagination > * {
  flex: none;
}
.ant-table-wrapper .ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-wrapper .ant-table-pagination-center {
  justify-content: center;
}
.ant-table-wrapper .ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-wrapper .ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
.ant-table-wrapper .ant-table-summary > tr > th,
.ant-table-wrapper .ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-wrapper div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters {
  outline: 0;
  cursor: pointer;
  transition:
    all 0.3s,
    left 0;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f0f0f0;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #52c41a;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f0f0f0;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: #f0f0f0;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
.ant-table-wrapper td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-wrapper .ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1;
}
.ant-table-wrapper .ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-wrapper .ant-table-column-sorters::after {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.ant-table-wrapper .ant-table-column-sorters-tooltip-target-sorter::after {
  content: none;
}
.ant-table-wrapper .ant-table-column-sorter {
  margin-inline-start: 4px;
  color: rgba(0, 0, 0, 0.29);
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-wrapper .ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-wrapper .ant-table-column-sorter-up.active,
.ant-table-wrapper .ant-table-column-sorter-down.active {
  color: #52c41a;
}
.ant-table-wrapper
  .ant-table-column-sorter
  .ant-table-column-sorter-up
  + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-wrapper .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: rgba(0, 0, 0, 0.57);
}
.ant-table-wrapper .ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-wrapper .ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin-block: -4px;
  margin-inline: 4px -8px;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.29);
  font-size: 12px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-wrapper .ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.06);
}
.ant-table-wrapper .ant-table-filter-trigger.active {
  color: #52c41a;
}
.ant-dropdown .ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  min-width: 120px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
  border-radius: unset;
  background-color: #fff;
}
.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: "Not Found";
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-tree {
  padding-block: 8px 0;
  padding-inline: 8px;
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-tree .ant-tree {
  padding: 0;
}
.ant-dropdown
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-tree
  .ant-tree-treenode
  .ant-tree-node-content-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.ant-dropdown
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper,
.ant-dropdown
  .ant-table-filter-dropdown
  .ant-table-filter-dropdown-tree
  .ant-tree-treenode-checkbox-checked
  .ant-tree-node-content-wrapper:hover {
  background-color: #f6ffed;
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-inline-start: 4px;
}
.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #f0f0f0;
}
.ant-dropdown .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-inline-start: 8px;
  color: rgba(0, 0, 0, 0.88);
}
.ant-dropdown .ant-table-filter-dropdown > ul,
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-inline-start: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-inline-end: 1px solid #f0f0f0;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr:not(:last-child)
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr:not(:last-child)
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr:not(:last-child)
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th::before,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th::before,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th::before,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th::before {
  background-color: transparent !important;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after {
  border-inline-end: 1px solid #f0f0f0;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  inset-inline-end: 1px;
  bottom: 0;
  border-inline-end: 1px solid #f0f0f0;
  content: "";
}
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-expanded-row
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-placeholder
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-expanded-row
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-placeholder
  > td {
  border-inline-end: 0;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-middle
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-middle
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-middle
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-middle
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table-wrapper
  .ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-wrapper .ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper .ant-table-bordered .ant-table-cell-scrollbar {
  border-inline-end: 1px solid #f0f0f0;
}
.ant-table-wrapper .ant-table .ant-table-title,
.ant-table-wrapper .ant-table .ant-table-header {
  border-radius: 16px 16px 0 0;
}
.ant-table-wrapper .ant-table .ant-table-title + .ant-table-container {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}
.ant-table-wrapper .ant-table .ant-table-title + .ant-table-container .ant-table-header,
.ant-table-wrapper .ant-table .ant-table-title + .ant-table-container table {
  border-radius: 0;
}
.ant-table-wrapper
  .ant-table
  .ant-table-title
  + .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.ant-table-wrapper
  .ant-table
  .ant-table-title
  + .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.ant-table-wrapper
  .ant-table
  .ant-table-title
  + .ant-table-container
  table
  > thead
  > tr:first-child
  td:first-child,
.ant-table-wrapper
  .ant-table
  .ant-table-title
  + .ant-table-container
  table
  > thead
  > tr:first-child
  td:last-child {
  border-radius: 0;
}
.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 16px;
  border-start-end-radius: 16px;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 16px;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 16px;
}
.ant-table-wrapper .ant-table-footer {
  border-radius: 0 0 16px 16px;
}
.ant-table-wrapper .ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-wrapper .ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: inline-flex;
  float: none;
  vertical-align: sub;
}
.ant-table-wrapper .ant-table-row-indent {
  height: 1px;
  float: left;
}
.ant-table-wrapper .ant-table-row-expand-icon {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 16px;
  transform: scale(0.9411764705882353);
  user-select: none;
}
.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover {
  color: #69b1ff;
}
.ant-table-wrapper .ant-table-row-expand-icon:active {
  color: #0958d9;
}
.ant-table-wrapper .ant-table-row-expand-icon:focus,
.ant-table-wrapper .ant-table-row-expand-icon:hover,
.ant-table-wrapper .ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-wrapper .ant-table-row-expand-icon::before,
.ant-table-wrapper .ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: "";
}
.ant-table-wrapper .ant-table-row-expand-icon::before {
  top: 7px;
  inset-inline-end: 3px;
  inset-inline-start: 3px;
  height: 1px;
}
.ant-table-wrapper .ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  inset-inline-start: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-wrapper .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-wrapper .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0);
}
.ant-table-wrapper .ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-wrapper .ant-table-row-expand-icon-spaced::before,
.ant-table-wrapper .ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-wrapper .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5px;
  margin-inline-end: 8px;
}
.ant-table-wrapper tr.ant-table-expanded-row > th,
.ant-table-wrapper tr.ant-table-expanded-row:hover > th,
.ant-table-wrapper tr.ant-table-expanded-row > td,
.ant-table-wrapper tr.ant-table-expanded-row:hover > td {
  background: rgba(0, 0, 0, 0.02);
}
.ant-table-wrapper tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
.ant-table-wrapper tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: 100%;
}
.ant-table-wrapper .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-wrapper .ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
.ant-table-wrapper .ant-table-summary > tr > th,
.ant-table-wrapper .ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-wrapper div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: #fff;
}
.ant-table-wrapper .ant-table-selection-col {
  width: 32px;
}
.ant-table-wrapper .ant-table-selection-col.ant-table-selection-col-with-dropdown {
  width: 48px;
}
.ant-table-wrapper .ant-table-bordered .ant-table-selection-col {
  width: 48px;
}
.ant-table-wrapper
  .ant-table-bordered
  .ant-table-selection-col.ant-table-selection-col-with-dropdown {
  width: 64px;
}
.ant-table-wrapper table tr th.ant-table-selection-column,
.ant-table-wrapper table tr td.ant-table-selection-column,
.ant-table-wrapper .ant-table-selection-column {
  padding-inline-end: 8px;
  padding-inline-start: 8px;
  text-align: center;
}
.ant-table-wrapper table tr th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-wrapper table tr td.ant-table-selection-column .ant-radio-wrapper,
.ant-table-wrapper .ant-table-selection-column .ant-radio-wrapper {
  margin-inline-end: 0;
}
.ant-table-wrapper table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
.ant-table-wrapper table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-wrapper .ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-wrapper .ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.ant-table-wrapper .ant-table-selection-extra .anticon {
  color: rgba(0, 0, 0, 0.29);
  font-size: 12px;
  vertical-align: baseline;
}
.ant-table-wrapper .ant-table-selection-extra .anticon:hover {
  color: rgba(0, 0, 0, 0.57);
}
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: #f6ffed;
}
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell-row-hover {
  background: #d9f7be;
}
.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-wrapper .ant-table-cell-fix-left,
.ant-table-wrapper .ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-wrapper .ant-table-cell-fix-left-first::after,
.ant-table-wrapper .ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}
.ant-table-wrapper .ant-table-cell-fix-left-all::after {
  display: none;
}
.ant-table-wrapper .ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}
.ant-table-wrapper .ant-table-container {
  position: relative;
}
.ant-table-wrapper .ant-table-container::before,
.ant-table-wrapper .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
  width: 30px;
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
}
.ant-table-wrapper .ant-table-container::before {
  inset-inline-start: 0;
}
.ant-table-wrapper .ant-table-container::after {
  inset-inline-end: 0;
}
.ant-table-wrapper .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
}
.ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
}
.ant-table-wrapper .ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-wrapper .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
}
.ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
}
.ant-table-wrapper .ant-table-fixed-column-gapped .ant-table-cell-fix-left-first::after,
.ant-table-wrapper .ant-table-fixed-column-gapped .ant-table-cell-fix-left-last::after,
.ant-table-wrapper .ant-table-fixed-column-gapped .ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table-fixed-column-gapped .ant-table-cell-fix-right-last::after {
  box-shadow: none;
}
.ant-table-wrapper .ant-table-sticky-holder {
  position: sticky;
  z-index: 3;
  background: #fff;
}
.ant-table-wrapper .ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  height: 8px !important;
  z-index: 3;
  display: flex;
  align-items: center;
  background: rgba(5, 5, 5, 0.06);
  border-top: 1px solid #f0f0f0;
  opacity: 0.65;
}
.ant-table-wrapper .ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-wrapper .ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  transition:
    all 0.3s,
    transform none;
  position: absolute;
  bottom: 0;
}
.ant-table-wrapper .ant-table-sticky-scroll-bar:hover,
.ant-table-wrapper .ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.88);
}
.ant-table-wrapper .ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-wrapper .ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-wrapper .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-wrapper .ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-wrapper .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-wrapper .ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-wrapper .ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-title,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-cell,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table.ant-table-middle tfoot > tr > th,
.ant-table-wrapper .ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-inline-end: -4px;
}
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table-wrapper
  .ant-table.ant-table-middle
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin-block: -12px;
  margin-inline: 40px -8px;
}
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-selection-extra {
  padding-inline-start: 2px;
}
.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-cell,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table-wrapper .ant-table.ant-table-small tfoot > tr > th,
.ant-table-wrapper .ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger {
  margin-inline-end: -4px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table-wrapper
  .ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin-block: -8px;
  margin-inline: 40px -8px;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-selection-extra {
  padding-inline-start: 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl table {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0);
}
.ant-table-wrapper-rtl .ant-table-container::before {
  inset-inline-start: unset;
  inset-inline-end: 0;
}
.ant-table-wrapper-rtl .ant-table-container::after {
  inset-inline-start: 0;
  inset-inline-end: unset;
}
.ant-table-wrapper-rtl .ant-table-container .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper .ant-table-tbody-virtual .ant-table-tbody-virtual-holder-inner > .ant-table-row,
.ant-table-wrapper
  .ant-table-tbody-virtual
  .ant-table-tbody-virtual-holder-inner
  > div:not(.ant-table-row)
  > .ant-table-row {
  display: flex;
  box-sizing: border-box;
  width: 100%;
}
.ant-table-wrapper .ant-table-tbody-virtual .ant-table-cell {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.2s;
}
.ant-table-wrapper
  .ant-table-tbody-virtual
  .ant-table-expanded-row
  .ant-table-expanded-row-cell.ant-table-expanded-row-cell-fixed {
  position: sticky;
  inset-inline-start: 0;
  overflow: hidden;
  width: calc(var(--virtual-width) - 1px);
  border-inline-end: none;
}
.ant-table-wrapper .ant-table-bordered .ant-table-tbody-virtual:after {
  content: "";
  inset-inline: 0;
  bottom: 0;
  border-bottom: 1px solid #f0f0f0;
  position: absolute;
}
.ant-table-wrapper .ant-table-bordered .ant-table-tbody-virtual .ant-table-cell {
  border-inline-end: 1px solid #f0f0f0;
}
.ant-table-wrapper
  .ant-table-bordered
  .ant-table-tbody-virtual
  .ant-table-cell.ant-table-cell-fix-right-first:before {
  content: "";
  position: absolute;
  inset-block: 0;
  inset-inline-start: -1px;
  border-inline-start: 1px solid #f0f0f0;
}
.ant-table-wrapper .ant-table-bordered.ant-table-virtual .ant-table-placeholder .ant-table-cell {
  border-inline-end: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
[class^="ant-tabs"],
[class*=" ant-tabs"] {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
[class^="ant-tabs"]::before,
[class*=" ant-tabs"]::before,
[class^="ant-tabs"]::after,
[class*=" ant-tabs"]::after {
  box-sizing: border-box;
}
[class^="ant-tabs"] [class^="ant-tabs"],
[class*=" ant-tabs"] [class^="ant-tabs"],
[class^="ant-tabs"] [class*=" ant-tabs"],
[class*=" ant-tabs"] [class*=" ant-tabs"] {
  box-sizing: border-box;
}
[class^="ant-tabs"] [class^="ant-tabs"]::before,
[class*=" ant-tabs"] [class^="ant-tabs"]::before,
[class^="ant-tabs"] [class*=" ant-tabs"]::before,
[class*=" ant-tabs"] [class*=" ant-tabs"]::before,
[class^="ant-tabs"] [class^="ant-tabs"]::after,
[class*=" ant-tabs"] [class^="ant-tabs"]::after,
[class^="ant-tabs"] [class*=" ant-tabs"]::after,
[class*=" ant-tabs"] [class*=" ant-tabs"]::after {
  box-sizing: border-box;
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-small.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 16px 16px;
}
.ant-tabs-card.ant-tabs-small.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 16px 16px 0 0;
}
.ant-tabs-card.ant-tabs-small.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 16px 16px 0;
}
.ant-tabs-card.ant-tabs-small.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 16px 0 0 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-menu-item .ant-tabs-dropdown-rtl {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: "";
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition:
    width 0.3s,
    left 0.3s,
    right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 32px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 40px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 32px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition:
    height 0.3s,
    top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #d9d9d9;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #d9d9d9;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 16px;
  outline: 0;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 4px 12px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item > span {
  flex: 1;
  white-space: nowrap;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item-remove:hover {
  color: #73d13d;
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #52c41a;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 16px 16px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 16px 16px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 16px 0 0 16px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 16px 16px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  display: flex;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: "";
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: opacity 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
  color: rgba(0, 0, 0, 0.88);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: "";
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  min-height: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 16px 16px 0 0;
  outline: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.88);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #73d13d;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus:not(:focus-visible),
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus:not(:focus-visible) {
  color: #389e0d;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus-visible,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus-visible {
  outline: 4px solid #b7eb8f;
  outline-offset: 1px;
  transition:
    outline-offset 0,
    outline 0;
}
.ant-tabs .ant-tabs-extra-content {
  flex: none;
}
.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #52c41a;
  pointer-events: none;
}
.ant-tabs .ant-tabs-tab {
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.88);
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
.ant-tabs .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab-remove:active {
  color: #389e0d;
}
.ant-tabs .ant-tabs-tab-btn:focus-visible,
.ant-tabs .ant-tabs-tab-remove:focus-visible {
  outline: 4px solid #b7eb8f;
  outline-offset: 1px;
  transition:
    outline-offset 0,
    outline 0;
}
.ant-tabs .ant-tabs-tab-btn {
  outline: 0;
  transition: all 0.3s;
}
.ant-tabs .ant-tabs-tab-btn .ant-tabs-tab-icon:not(:last-child) {
  margin-inline-end: 12px;
}
.ant-tabs .ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs .ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.88);
}
.ant-tabs .ant-tabs-tab:hover {
  color: #73d13d;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #52c41a;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-remove:focus,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs .ant-tabs-tab .anticon:not(:last-child) {
  margin-right: 12px;
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs .ant-tabs-content {
  position: relative;
  width: 100%;
}
.ant-tabs .ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs .ant-tabs-tabpane {
  outline: 0;
}
.ant-tabs .ant-tabs-tabpane-hidden {
  display: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*=".ant-tabs-nav-wrap-ping"]),
.ant-tabs-centered
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-wrap:not([class*=".ant-tabs-nav-wrap-ping"]) {
  justify-content: center;
}
.ant-tabs .ant-tabs-switch-appear,
.ant-tabs .ant-tabs-switch-enter {
  transition: none;
}
.ant-tabs .ant-tabs-switch-appear-start,
.ant-tabs .ant-tabs-switch-enter-start {
  opacity: 0;
}
.ant-tabs .ant-tabs-switch-appear-active,
.ant-tabs .ant-tabs-switch-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.ant-tabs .ant-tabs-switch-leave {
  position: absolute;
  transition: none;
  inset: 0;
}
.ant-tabs .ant-tabs-switch-leave-start {
  opacity: 1;
}
.ant-tabs .ant-tabs-switch-leave-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-tag {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-tag::before,
.ant-tag::after {
  box-sizing: border-box;
}
.ant-tag [class^="ant-tag"],
.ant-tag [class*=" ant-tag"] {
  box-sizing: border-box;
}
.ant-tag [class^="ant-tag"]::before,
.ant-tag [class*=" ant-tag"]::before,
.ant-tag [class^="ant-tag"]::after,
.ant-tag [class*=" ant-tag"]::after {
  box-sizing: border-box;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  display: inline-block;
  height: auto;
  margin-inline-end: 8px;
  padding-inline: 7px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s;
  text-align: start;
  position: relative;
}
.ant-tag.ant-tag-rtl {
  direction: rtl;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.88);
}
.ant-tag .ant-tag-close-icon {
  margin-inline-start: 3px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.2s;
}
.ant-tag .ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.88);
}
.ant-tag.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag.ant-tag-has-color,
.ant-tag.ant-tag-has-color a,
.ant-tag.ant-tag-has-color a:hover,
.ant-tag.ant-tag-has-color .anticon-close,
.ant-tag.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #52c41a;
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #52c41a;
}
.ant-tag-checkable-checked:hover {
  background-color: #73d13d;
}
.ant-tag-checkable:active {
  background-color: #389e0d;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-inline-start: 7px;
}
.ant-tag-borderless {
  border-color: transparent;
  background: #fafafa;
}
.ant-spin {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-spin::before,
.ant-spin::after {
  box-sizing: border-box;
}
.ant-spin [class^="ant-spin"],
.ant-spin [class*=" ant-spin"] {
  box-sizing: border-box;
}
.ant-spin [class^="ant-spin"]::before,
.ant-spin [class*=" ant-spin"]::before,
.ant-spin [class^="ant-spin"]::after,
.ant-spin [class*=" ant-spin"]::after {
  box-sizing: border-box;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #52c41a;
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: absolute;
  display: none;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: relative;
  display: inline-block;
  opacity: 1;
}
.ant-spin .ant-spin-text {
  font-size: 14px;
  padding-top: 5px;
}
.ant-spin-fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1000;
  inset: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}
.ant-spin-fullscreen-show {
  opacity: 1;
  visibility: visible;
}
.ant-spin-fullscreen .ant-spin .ant-spin-dot-holder {
  color: #fff;
}
.ant-spin-fullscreen .ant-spin .ant-spin-text {
  color: #fff;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  inset-inline-start: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-nested-loading .ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-nested-loading .ant-spin-container::after {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: "";
  pointer-events: none;
}
.ant-spin-nested-loading .ant-spin-blur {
  clear: both;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.ant-spin-nested-loading .ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin .ant-spin-dot-holder {
  width: 1em;
  height: 1em;
  font-size: 20px;
  display: inline-block;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  transform-origin: 50% 50%;
  line-height: 1;
  color: #52c41a;
}
.ant-spin .ant-spin-dot-holder-hidden {
  transform: scale(0.3);
  opacity: 0;
}
.ant-spin .ant-spin-dot-progress {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  inset-inline-start: 50%;
}
.ant-spin .ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background: currentColor;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation-name: css-dev-only-do-not-override-5gkde5-antSpinMove;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}
.ant-spin .ant-spin-dot-item:nth-child(1) {
  top: 0;
  inset-inline-start: 0;
  animation-delay: 0;
}
.ant-spin .ant-spin-dot-item:nth-child(2) {
  top: 0;
  inset-inline-end: 0;
  animation-delay: 0.4s;
}
.ant-spin .ant-spin-dot-item:nth-child(3) {
  inset-inline-end: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin .ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  inset-inline-start: 0;
  animation-delay: 1.2s;
}
.ant-spin .ant-spin-dot-spin {
  transform: rotate(45deg);
  animation-name: css-dev-only-do-not-override-5gkde5-antRotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.ant-spin .ant-spin-dot-circle {
  stroke-linecap: round;
  transition:
    stroke-dashoffset 0.3s ease,
    stroke-dasharray 0.3s ease,
    stroke 0.3s ease,
    stroke-width 0.3s ease,
    opacity 0.3s ease;
  fill-opacity: 0;
  stroke: currentcolor;
}
.ant-spin .ant-spin-dot-circle-bg {
  stroke: rgba(0, 0, 0, 0.06);
}
.ant-spin-sm .ant-spin-dot,
.ant-spin-sm .ant-spin-dot-holder {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot-holder i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot,
.ant-spin-lg .ant-spin-dot-holder {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot-holder i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
.ant-space {
  display: inline-flex;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space .ant-space-item:empty {
  display: none;
}
.ant-space .ant-space-item > .ant-badge-not-a-wrapper:only-child {
  display: block;
}
.ant-space-gap-row-small {
  row-gap: 8px;
}
.ant-space-gap-row-middle {
  row-gap: 16px;
}
.ant-space-gap-row-large {
  row-gap: 24px;
}
.ant-space-gap-col-small {
  column-gap: 8px;
}
.ant-space-gap-col-middle {
  column-gap: 16px;
}
.ant-space-gap-col-large {
  column-gap: 24px;
}
.ant-space-block {
  display: flex;
  width: 100%;
}
.ant-space-vertical {
  flex-direction: column;
}

.ant-badge {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}

.ant-badge::before,
.ant-badge::after {
  box-sizing: border-box;
}

.ant-badge [class^="ant-badge"],
.ant-badge [class*=" ant-badge"] {
  box-sizing: border-box;
}

.ant-badge [class^="ant-badge"]::before,
.ant-badge [class*=" ant-badge"]::before,
.ant-badge [class^="ant-badge"]::after,
.ant-badge [class*=" ant-badge"]::after {
  box-sizing: border-box;
}

.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: fit-content;
}

.ant-badge .ant-badge-count {
  display: inline-flex;
  justify-content: center;
  z-index: auto;
  min-width: 20px;
  height: 20px;
  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #ffffff;
  transition: background 0.2s;
}

.ant-badge .ant-badge-count a {
  color: #ffffff;
}

.ant-badge .ant-badge-count a:hover {
  color: #ffffff;
}

a:hover .ant-badge .ant-badge-count {
  background: #ff7875;
}

.ant-badge .ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}

.ant-badge .ant-badge-multiple-words {
  padding: 0 8px;
}

.ant-badge .ant-badge-multiple-words bdi {
  unicode-bidi: plaintext;
}

.ant-badge .ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #ffffff;
}

.ant-badge .ant-badge-count,
.ant-badge .ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.ant-badge .ant-badge-count.anticon-spin,
.ant-badge .ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation-name: css-dev-only-do-not-override-5gkde5-antBadgeLoadingCircle;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ant-badge.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}

.ant-badge.ant-badge-status .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}

.ant-badge.ant-badge-status .ant-badge-status-success {
  background-color: #52c41a;
}

.ant-badge.ant-badge-status .ant-badge-status-processing {
  overflow: visible;
  color: #1677ff;
  background-color: #1677ff;
  border-color: currentcolor;
}

.ant-badge.ant-badge-status .ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 50%;
  animation-name: css-dev-only-do-not-override-5gkde5-antStatusProcessing;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  content: "";
}

.ant-badge.ant-badge-status .ant-badge-status-default {
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-badge.ant-badge-status .ant-badge-status-error {
  background-color: #ff4d4f;
}

.ant-badge.ant-badge-status .ant-badge-status-warning {
  background-color: #faad14;
}

.ant-badge.ant-badge-status .ant-badge-status-text {
  margin-inline-start: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
}

.ant-badge.ant-badge .ant-badge-color-blue {
  background: #1677ff;
}

.ant-badge.ant-badge .ant-badge-color-blue:not(.ant-badge-count) {
  color: #1677ff;
}

a:hover .ant-badge.ant-badge .ant-badge-color-blue {
  background: #1677ff;
}

.ant-badge.ant-badge .ant-badge-color-purple {
  background: #722ed1;
}

.ant-badge.ant-badge .ant-badge-color-purple:not(.ant-badge-count) {
  color: #722ed1;
}

a:hover .ant-badge.ant-badge .ant-badge-color-purple {
  background: #722ed1;
}

.ant-badge.ant-badge .ant-badge-color-cyan {
  background: #13c2c2;
}

.ant-badge.ant-badge .ant-badge-color-cyan:not(.ant-badge-count) {
  color: #13c2c2;
}

a:hover .ant-badge.ant-badge .ant-badge-color-cyan {
  background: #13c2c2;
}

.ant-badge.ant-badge .ant-badge-color-green {
  background: #52c41a;
}

.ant-badge.ant-badge .ant-badge-color-green:not(.ant-badge-count) {
  color: #52c41a;
}

a:hover .ant-badge.ant-badge .ant-badge-color-green {
  background: #52c41a;
}

.ant-badge.ant-badge .ant-badge-color-magenta {
  background: #eb2f96;
}

.ant-badge.ant-badge .ant-badge-color-magenta:not(.ant-badge-count) {
  color: #eb2f96;
}

a:hover .ant-badge.ant-badge .ant-badge-color-magenta {
  background: #eb2f96;
}

.ant-badge.ant-badge .ant-badge-color-pink {
  background: #eb2f96;
}

.ant-badge.ant-badge .ant-badge-color-pink:not(.ant-badge-count) {
  color: #eb2f96;
}

a:hover .ant-badge.ant-badge .ant-badge-color-pink {
  background: #eb2f96;
}

.ant-badge.ant-badge .ant-badge-color-red {
  background: #f5222d;
}

.ant-badge.ant-badge .ant-badge-color-red:not(.ant-badge-count) {
  color: #f5222d;
}

a:hover .ant-badge.ant-badge .ant-badge-color-red {
  background: #f5222d;
}

.ant-badge.ant-badge .ant-badge-color-orange {
  background: #fa8c16;
}

.ant-badge.ant-badge .ant-badge-color-orange:not(.ant-badge-count) {
  color: #fa8c16;
}

a:hover .ant-badge.ant-badge .ant-badge-color-orange {
  background: #fa8c16;
}

.ant-badge.ant-badge .ant-badge-color-yellow {
  background: #fadb14;
}

.ant-badge.ant-badge .ant-badge-color-yellow:not(.ant-badge-count) {
  color: #fadb14;
}

a:hover .ant-badge.ant-badge .ant-badge-color-yellow {
  background: #fadb14;
}

.ant-badge.ant-badge .ant-badge-color-volcano {
  background: #fa541c;
}

.ant-badge.ant-badge .ant-badge-color-volcano:not(.ant-badge-count) {
  color: #fa541c;
}

a:hover .ant-badge.ant-badge .ant-badge-color-volcano {
  background: #fa541c;
}

.ant-badge.ant-badge .ant-badge-color-geekblue {
  background: #2f54eb;
}

.ant-badge.ant-badge .ant-badge-color-geekblue:not(.ant-badge-count) {
  color: #2f54eb;
}

a:hover .ant-badge.ant-badge .ant-badge-color-geekblue {
  background: #2f54eb;
}

.ant-badge.ant-badge .ant-badge-color-lime {
  background: #a0d911;
}

.ant-badge.ant-badge .ant-badge-color-lime:not(.ant-badge-count) {
  color: #a0d911;
}

a:hover .ant-badge.ant-badge .ant-badge-color-lime {
  background: #a0d911;
}

.ant-badge.ant-badge .ant-badge-color-gold {
  background: #faad14;
}

.ant-badge.ant-badge .ant-badge-color-gold:not(.ant-badge-count) {
  color: #faad14;
}

a:hover .ant-badge.ant-badge .ant-badge-color-gold {
  background: #faad14;
}

.ant-badge .ant-badge-zoom-appear,
.ant-badge .ant-badge-zoom-enter {
  animation-name: css-dev-only-do-not-override-5gkde5-antZoomBadgeIn;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}

.ant-badge .ant-badge-zoom-leave {
  animation-name: css-dev-only-do-not-override-5gkde5-antZoomBadgeOut;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}

.ant-badge.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation-name: css-dev-only-do-not-override-5gkde5-antNoWrapperZoomBadgeIn;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ant-badge.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation-name: css-dev-only-do-not-override-5gkde5-antNoWrapperZoomBadgeOut;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ant-badge.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}

.ant-badge.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}

.ant-badge.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}

.ant-badge .ant-scroll-number {
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ant-badge .ant-scroll-number .ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.ant-badge .ant-scroll-number .ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.ant-badge .ant-scroll-number .ant-scroll-number-symbol {
  vertical-align: top;
}

.ant-badge-rtl {
  direction: rtl;
}

.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl .ant-scroll-number-custom-component {
  transform: translate(-50%, -50%);
}
.ant-empty {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}
.ant-empty::before,
.ant-empty::after {
  box-sizing: border-box;
}
.ant-empty [class^="ant-empty"],
.ant-empty [class*=" ant-empty"] {
  box-sizing: border-box;
}
.ant-empty [class^="ant-empty"]::before,
.ant-empty [class*=" ant-empty"]::before,
.ant-empty [class^="ant-empty"]::after,
.ant-empty [class*=" ant-empty"]::after {
  box-sizing: border-box;
}
.ant-empty {
  margin-inline: 8px;
  font-size: 14px;
  line-height: 1.5714285714285714;
  text-align: center;
}
.ant-empty .ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
  opacity: 1;
}
.ant-empty .ant-empty-image img {
  height: 100%;
}
.ant-empty .ant-empty-image svg {
  max-width: 100%;
  height: 100%;
  margin: auto;
}
.ant-empty .ant-empty-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-empty .ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin-block: 32px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-empty-normal .ant-empty-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin-block: 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}

.ant-steps {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}

.ant-steps::before,
.ant-steps::after {
  box-sizing: border-box;
}

.ant-steps [class^="ant-steps"],
.ant-steps [class*=" ant-steps"] {
  box-sizing: border-box;
}

.ant-steps [class^="ant-steps"]::before,
.ant-steps [class*=" ant-steps"]::before,
.ant-steps [class^="ant-steps"]::after,
.ant-steps [class*=" ant-steps"]::after {
  box-sizing: border-box;
}

.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  display: flex;
  width: 100%;
  text-align: initial;
}

.ant-steps .ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}

.ant-steps .ant-steps-item:last-child {
  flex: none;
}

.ant-steps .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps
  .ant-steps-item:last-child
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: none;
}

.ant-steps .ant-steps-item-container {
  outline: none;
}

.ant-steps .ant-steps-item-container:focus-visible .ant-steps-item-icon {
  outline: 4px solid #b7eb8f;
  outline-offset: 1px;
  transition:
    outline-offset 0s,
    outline 0s;
}

.ant-steps .ant-steps-item-icon,
.ant-steps .ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}

.ant-steps .ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-start: 0;
  margin-inline-end: 8px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  border: 1px solid transparent;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.ant-steps .ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #52c41a;
  line-height: 1;
}

.ant-steps .ant-steps-item-tail {
  position: absolute;
  top: 16px;
  inset-inline-start: 0;
  width: 100%;
}

.ant-steps .ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: rgba(5, 5, 5, 0.06);
  border-radius: 1px;
  transition: background 0.3s;
  content: "";
}

.ant-steps .ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-inline-end: 16px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 32px;
}

.ant-steps .ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  inset-inline-start: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: rgba(5, 5, 5, 0.06);
  content: "";
}

.ant-steps .ant-steps-item-subtitle {
  display: inline;
  margin-inline-start: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}

.ant-steps .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: rgba(0, 0, 0, 0.06);
  border-color: transparent;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.65);
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}

.ant-steps .ant-steps-item-wait.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #52c41a;
  border-color: #52c41a;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #52c41a;
}

.ant-steps .ant-steps-item-process.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  color: #52c41a;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.88);
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.88);
}

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 600;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #f6ffed;
  border-color: #f6ffed;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #52c41a;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #52c41a;
}

.ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  color: #52c41a;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.88);
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #52c41a;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #52c41a;
}

.ant-steps .ant-steps-item-error .ant-steps-item-icon {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}

.ant-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}

.ant-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}

.ant-steps .ant-steps-item-error.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}

.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #ff4d4f;
}

.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps
  .ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: #ff4d4f;
}

.ant-steps .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: rgba(5, 5, 5, 0.06);
}

.ant-steps .ant-steps-item.ant-steps-next-error > .ant-steps-item-title::after {
  background: #ff4d4f;
}

.ant-steps .ant-steps-item-disabled {
  cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role="button"] {
  cursor: pointer;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]
  .ant-steps-item-description,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]
  .ant-steps-item-icon
  .ant-steps-icon {
  transition: color 0.3s;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-subtitle,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-description {
  color: #52c41a;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  border-color: #52c41a;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #52c41a;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-inline-start: 16px;
  white-space: nowrap;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-inline-start: 0;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:last-child
  .ant-steps-item-title {
  padding-inline-end: 0;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}

.ant-steps .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}

.ant-steps
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  top: 0;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}

.ant-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-inline-start: 12px;
}

.ant-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item:first-child {
  padding-inline-start: 0;
}

.ant-steps.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline: 0 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}

.ant-steps.ant-steps-small .ant-steps-item-title {
  padding-inline-end: 12px;
  font-size: 14px;
  line-height: 24px;
}

.ant-steps.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}

.ant-steps.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.ant-steps.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}

.ant-steps.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}

.ant-steps.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}

.ant-steps.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}

.ant-steps.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-inline-start: 0;
  overflow: visible;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-inline-end: 16px;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}

.ant-steps.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  inset-inline-start: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}

.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}

.ant-steps.ant-steps-vertical
  > .ant-steps-item:not(:last-child)
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  display: block;
}

.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: none;
}

.ant-steps.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  inset-inline-start: 11px;
  padding: 30px 0 6px;
}

.ant-steps.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}

.ant-steps .ant-steps-horizontal .ant-steps-item-tail {
  transform: translateY(-50%);
}

.ant-steps.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-inline-start: 56px;
  padding: 0 24px;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 112px;
  margin-top: 12px;
  text-align: center;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-inline-start: 40px;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-title {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}

.ant-steps.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-inline-start: 0;
  line-height: 1.5714285714285714;
}

.ant-steps.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-inline-start: 44px;
}

.ant-steps.ant-steps-dot .ant-steps-item-title,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5714285714285714;
}

.ant-steps.ant-steps-dot .ant-steps-item-tail,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2.5px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline: 70px 0;
  padding: 0;
}

.ant-steps.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 24px);
  height: 3px;
  margin-inline-start: 12px;
}

.ant-steps.ant-steps-dot .ant-steps-item-icon,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-inline-start: 66px;
  padding-inline-end: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}

.ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
}

.ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  inset-inline-start: -26px;
  width: 60px;
  height: 32px;
  background: transparent;
  content: "";
}

.ant-steps.ant-steps-dot .ant-steps-item-content,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}

.ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
  margin-inline-start: 65px;
}

.ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps.ant-steps-dot.ant-steps-small
  .ant-steps-item-process
  .ant-steps-icon:first-child
  .ant-steps-icon-dot {
  inset-inline-start: 0;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 12px;
  margin-inline-start: 0;
  background: none;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  margin-top: 11px;
  top: 0;
  inset-inline-start: -1px;
  margin-inline-start: 0;
}

.ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  top: 12px;
  inset-inline-start: 0;
  margin: 0;
  padding: 16px 0 8px;
}

.ant-steps.ant-steps-vertical.ant-steps-dot
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  margin-inline-start: 3.5px;
}

.ant-steps.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 8px;
}

.ant-steps.ant-steps-vertical.ant-steps-dot.ant-steps-small
  .ant-steps-item-process
  .ant-steps-item-icon {
  margin-top: 7px;
}

.ant-steps.ant-steps-vertical.ant-steps-dot.ant-steps-small
  .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  top: 8px;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  inset-inline-start: 0;
}

.ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}

.ant-steps.ant-steps-navigation {
  padding-top: 12px;
}

.ant-steps.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-inline-start: -12px;
}

.ant-steps.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}

.ant-steps.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-inline-start: -16px;
  padding-bottom: 12px;
  text-align: start;
  transition: opacity 0.3s;
}

.ant-steps.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}

.ant-steps.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-inline-end: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-steps.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}

.ant-steps.ant-steps-navigation
  .ant-steps-item:not(.ant-steps-item-active)
  .ant-steps-item-container[role="button"] {
  cursor: pointer;
}

.ant-steps.ant-steps-navigation
  .ant-steps-item:not(.ant-steps-item-active)
  .ant-steps-item-container[role="button"]:hover {
  opacity: 0.85;
}

.ant-steps.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}

.ant-steps.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}

.ant-steps.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: calc(50% - 6px);
  inset-inline-start: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-inline-start: none;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.25);
  transform: translateY(-50%) translateX(-50%) rotate(45deg);
  content: "";
}

.ant-steps.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  inset-inline-start: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #52c41a;
  transition:
    width 0.3s,
    inset-inline-start 0.3s;
  transition-timing-function: ease-out;
  content: "";
}

.ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  inset-inline-start: 0;
  width: 100%;
}

.ant-steps.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-inline-end: 0;
}

.ant-steps.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}

.ant-steps.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  inset-inline-end: 0;
  inset-inline-start: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}

.ant-steps.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  inset-inline-start: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: translateY(-50%) translateX(-50%) rotate(135deg);
}

.ant-steps.ant-steps-navigation.ant-steps-vertical > .ant-steps-item:last-child::after {
  display: none;
}

.ant-steps.ant-steps-navigation.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  visibility: hidden;
}

.ant-steps.ant-steps-navigation.ant-steps-horizontal
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  visibility: hidden;
}

.ant-steps.ant-steps-rtl {
  direction: rtl;
}

.ant-steps.ant-steps-rtl .ant-steps-item-subtitle {
  float: left;
}

.ant-steps.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  transform: rotate(-45deg);
}

.ant-steps.ant-steps-rtl.ant-steps-vertical > .ant-steps-item::after {
  transform: rotate(225deg);
}

.ant-steps.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
}

.ant-steps.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}

.ant-steps.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}

.ant-steps.ant-steps-with-progress
  .ant-steps-item-process
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #fff;
}

.ant-steps.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-inline-start: 4px;
}

.ant-steps.ant-steps-with-progress.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  top: 4px;
  inset-inline-start: 19px;
}

.ant-steps.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child,
.ant-steps.ant-steps-with-progress.ant-steps-small.ant-steps-horizontal
  .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-inline-start: 4px;
}

.ant-steps.ant-steps-with-progress.ant-steps-small.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  inset-inline-start: 15px;
}

.ant-steps.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 20px;
}

.ant-steps.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}

.ant-steps.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  inset-inline-start: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ant-steps.ant-steps-with-progress .ant-steps-item-icon .ant-progress-inner {
  width: 40px !important;
  height: 40px !important;
}

.ant-steps.ant-steps-with-progress.ant-steps-small.ant-steps-label-vertical
  .ant-steps-item
  .ant-steps-item-tail {
  top: 16px;
}

.ant-steps.ant-steps-with-progress.ant-steps-small .ant-steps-item-icon .ant-progress-inner {
  width: 28px !important;
  height: 28px !important;
}

.ant-steps.ant-steps-inline {
  width: auto;
  display: inline-flex;
}

.ant-steps.ant-steps-inline .ant-steps-item {
  flex: none;
}

.ant-steps.ant-steps-inline .ant-steps-item-container {
  padding: 9px 4px 0;
  margin: 0 2px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ant-steps.ant-steps-inline .ant-steps-item-container:hover {
  background: rgba(0, 0, 0, 0.04);
}

.ant-steps.ant-steps-inline .ant-steps-item-container[role="button"]:hover {
  opacity: 1;
}

.ant-steps.ant-steps-inline .ant-steps-item-icon {
  width: 6px;
  height: 6px;
  margin-inline-start: calc(50% - 3px);
}

.ant-steps.ant-steps-inline .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
}

.ant-steps.ant-steps-inline .ant-steps-item-icon .ant-steps-icon-dot {
  border-radius: 3px;
}

.ant-steps.ant-steps-inline .ant-steps-item-icon .ant-steps-icon-dot::after {
  display: none;
}

.ant-steps.ant-steps-inline .ant-steps-item-content {
  width: auto;
  margin-top: 7px;
}

.ant-steps.ant-steps-inline .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1.6666666666666667;
  font-weight: normal;
  margin-bottom: 2px;
}

.ant-steps.ant-steps-inline .ant-steps-item-description {
  display: none;
}

.ant-steps.ant-steps-inline .ant-steps-item-tail {
  margin-inline-start: 0;
  top: 12px;
  transform: translateY(-50%);
}

.ant-steps.ant-steps-inline .ant-steps-item-tail:after {
  width: 100%;
  height: 1px;
  border-radius: 0;
  margin-inline-start: 0;
  background: #f0f0f0;
}

.ant-steps.ant-steps-inline .ant-steps-item:first-child .ant-steps-item-tail {
  width: 50%;
  margin-inline-start: 50%;
}

.ant-steps.ant-steps-inline .ant-steps-item:last-child .ant-steps-item-tail {
  display: block;
  width: 50%;
}

.ant-steps.ant-steps-inline
  .ant-steps-item-wait
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
}

.ant-steps.ant-steps-inline
  .ant-steps-item-wait
  .ant-steps-item-container
  .ant-steps-item-content
  .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25);
}

.ant-steps.ant-steps-inline .ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}

.ant-steps.ant-steps-inline
  .ant-steps-item-finish
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
}

.ant-steps.ant-steps-inline
  .ant-steps-item-finish
  .ant-steps-item-container
  .ant-steps-item-content
  .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25);
}

.ant-steps.ant-steps-inline
  .ant-steps-item-error
  .ant-steps-item-container
  .ant-steps-item-content
  .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25);
}

.ant-steps.ant-steps-inline .ant-steps-item-active .ant-steps-item-icon,
.ant-steps.ant-steps-inline .ant-steps-item-process .ant-steps-item-icon {
  width: 6px;
  height: 6px;
  margin-inline-start: calc(50% - 3px);
  top: 0;
}

.ant-steps.ant-steps-inline
  .ant-steps-item-active
  .ant-steps-item-container
  .ant-steps-item-content
  .ant-steps-item-title,
.ant-steps.ant-steps-inline
  .ant-steps-item-process
  .ant-steps-item-container
  .ant-steps-item-content
  .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25);
}

.ant-steps.ant-steps-inline
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25);
}
.ant-breadcrumb [class^="ant-breadcrumb"],
.ant-breadcrumb [class*=" ant-breadcrumb"] {
  box-sizing: border-box;
}

.ant-breadcrumb [class^="ant-breadcrumb"]::before,
.ant-breadcrumb [class*=" ant-breadcrumb"]::before,
.ant-breadcrumb [class^="ant-breadcrumb"]::after,
.ant-breadcrumb [class*=" ant-breadcrumb"]::after {
  box-sizing: border-box;
}

.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.ant-breadcrumb .anticon {
  font-size: 14px;
}

.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.2s;
  padding: 0 4px;
  border-radius: 8px;
  height: 22px;
  display: inline-block;
  margin-inline: -4px;
}

.ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-breadcrumb a:focus-visible {
  outline: 4px solid #b7eb8f;
  outline-offset: 1px;
  transition:
    outline-offset 0s,
    outline 0s;
}

.ant-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.88);
}

.ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 8px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-breadcrumb .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb .ant-breadcrumb-link > .anticon + a {
  margin-inline-start: 4px;
}

.ant-breadcrumb .ant-breadcrumb-overlay-link {
  border-radius: 8px;
  height: 22px;
  display: inline-block;
  padding: 0 4px;
  margin-inline: -4px;
}

.ant-breadcrumb .ant-breadcrumb-overlay-link > .anticon {
  margin-inline-start: 4px;
  font-size: 12px;
}

.ant-breadcrumb .ant-breadcrumb-overlay-link:hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: rgba(0, 0, 0, 0.06);
}

.ant-breadcrumb .ant-breadcrumb-overlay-link:hover a {
  color: rgba(0, 0, 0, 0.88);
}

.ant-breadcrumb .ant-breadcrumb-overlay-link a:hover {
  background-color: transparent;
}

.ant-breadcrumb.ant-breadcrumb-rtl {
  direction: rtl;
}
